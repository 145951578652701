let requiredArr = [
  'companyName',
  'lastJobTitle',
  'lineManagerName',
  'lineManagerEmail',
  'hrName',
  'hrEmail',
  'periodEnd',
  'periodStart',
  'leaveReason',
]  
  var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('邮箱为空'));
      } else {
      const regEmail =
      /^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          if (!regEmail.test(value)) {
          callback(new Error('邮箱规范不正确'));
      }
        callback();
      }
    };
export const rules = {}
requiredArr.forEach((item) => {
  rules[item] = [{ required: true ,trigger: 'blur'}]
  if(item=='lineManagerEmail'||item=='hrEmail'){
    rules[item] = [{ validator:validateEmail,required: true ,trigger: 'blur'}]
  }
})


