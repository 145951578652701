<template>
  <el-dialog
    :visible.sync="dialogVisibleWork"
    title="工作经历(请自现职填起) Employment History (from present/ most current employment)"
    width="50%"
    append-to-body
    custom-class="EmploymentHistoryDialog"
    :before-close="close"
  >
    <el-form
      ref="ruleWorkFormRef"
      :model="ruleForm"
      :rules="rules"
      inline
      label-position="top"
      class="demo-ruleForm"
    >
      <el-row :gutter="24">
        <el-col :lg="12">
          <el-form-item label="就职起始 Period Start" prop="periodStart">
            <el-date-picker
              v-model="ruleForm.periodStart"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="就职起始 Period Start"
            />
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="就职截止 Period End" prop="periodEnd">
            <el-date-picker
              v-model="ruleForm.periodEnd"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="就职截止 Period End"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :lg="12">
          <el-form-item label="公司名称 Company Name" prop="companyName">
            <el-input v-model="ruleForm.companyName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item
            label="离职前职位名称 Last Job Title"
            prop="lastJobTitle"
          >
            <el-input v-model="ruleForm.lastJobTitle"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :lg="12">
          <el-form-item label="证明人 Line Manager Name" prop="lineManagerName">
            <el-input v-model="ruleForm.lineManagerName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="电话 Line Manager Phone">
            <el-input v-model="ruleForm.lineManagerPhone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :lg="12">
          <el-form-item
            label="邮箱 Line Manager Email Address"
            prop="lineManagerEmail"
          >
            <el-input v-model="ruleForm.lineManagerEmail"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="证明人 HR Name" prop="hrName">
            <el-input v-model="ruleForm.hrName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :lg="12">
          <el-form-item label="电话 HR Phone">
            <el-input v-model="ruleForm.hrPhone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="邮箱 HR Email Address" prop="hrEmail">
            <el-input v-model="ruleForm.hrEmail"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :lg="24">
          <el-form-item label="离职原因 Reason for Leaving" prop="leaveReason">
            <el-select
              v-model="ruleForm.leaveReason"
              placeholder="离职原因 Reason for Leaving"
            >
              <el-option
                v-for="item in leaveReasonList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :lg="24">
          <el-form-item
            label="如果是被动离职，请提供补充说明 If Involuntary Leaving, please provide more information"
          >
            <el-input v-model="ruleForm.leaveComments"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">Cancel</el-button>
        <el-button type="primary" @click="confirm">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { rules } from './rulesWork'

export default {
  name: 'employeeJoin',
  mixins: [$PCommon.TableMixin],
  components: {},
  watch: {
    rowItem(newValue, oldValue) {
      console.log('----------------', newValue)
      this.ruleForm = newValue
    }
  },
  props: {
    dialogVisibleWork: {
      type: Boolean
    },
    rowItem: {
      type: Object
    }
  },

  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight + 'px'
    return {
      maxHeight: h,
      queryLoading: false,
      tableData: [],
      rules: rules,

      dialogVisible: true,
      ruleForm: {
        companyName: '',
        lastJobTitle: '',
        lineManagerName: '',
        lineManagerPhone: '',
        lineManagerEmail: '',
        hrName: '',
        hrPhone: '',
        hrEmail: '',
        periodEnd: '',
        periodStart: '',
        leaveReason: '',
        leaveComments: '',
      },
      leaveReasonList: [
        {
          label: '主动离职',
          value: '1'
        },
        {
          label: '被动离职',
          value: '2'
        }
      ]
    }
  },
  computed: {},

  async created() {
    console.log('00000000000', this.dialogVisibleWork)
  },
  methods: {
    confirm() {
      this.$refs.ruleWorkFormRef.validate((valid) => {
        if (valid) {
          this.$emit('confirm')
        } else {
          return
        }
      })
    },
    close() {
      this.$emit('close')
    }
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
.EmploymentHistoryDialog {
  .el-dialog__body {
    padding: 0;
  }
}
</style>
