let baseData = [
  {
    nameTitle: '基础信息',
    nameKey: [
      {
        flexId: '1',
        lable: 'businessTitleEn',
        lableTitle: 'Business Title in English',
        content: ''
      },
      {
        flexId: '1',
        lable: 'businessTitleCn',
        lableTitle: 'Business Title in Chinese',
        content: ''
      },
      {
        flexId: '1',
        lable: 'departmentName',
        lableTitle: 'Department/Team',
        content: ''
      },
      { flexId: '1', lable: 'team', lableTitle: 'Team', content: '' },
      {
        flexId: '1',
        lable: 'parentFunction',
        lableTitle: 'Parent Function',
        content: ''
      },

      {
        flexId: '1',
        lable: 'childFunction',
        lableTitle: 'Child Function',
        content: ''
      },
      {
        flexId: '1',
        lable: 'internalBrand',
        lableTitle: 'Internal Brand',
        content: ''
      },
      { flexId: '1', lable: 'jobGrade', lableTitle: 'Job Grade', content: '' },
      {
        flexId: '1',
        lable: 'lineManagerLlid',
        lableTitle: 'Lion ID of LM',
        content: ''
      },
      /* {
        flexId: '1',
        lable: 'lineManagerName',
        lableTitle: 'Name of LM',
        content: ''
      },
      {
        flexId: '1',
        lable: 'lineManagerEmail',
        lableTitle: 'LM Email',
        content: ''
      }, */
      {
        flexId: '1',
        lable: 'englishName',
        lableTitle: 'English Name',
        content: ''
      },

      {
        flexId: '1',
        lable: 'chineseName',
        lableTitle: 'Local Name',
        content: ''
      },
      { flexId: '1', lable: 'age', lableTitle: 'Age', content: '' },
      { flexId: '1', lable: 'gender', lableTitle: 'Gender', content: '' },
      {
        flexId: '1',
        lable: 'nationality',
        lableTitle: 'Nationality',
        content: ''
      },
      { flexId: '1', lable: 'ethnicityName', lableTitle: 'Ethnicity', content: '' },

      {
        flexId: '1',
        lable: 'idNumber',
        lableTitle: 'ID/PassportNo',
        content: ''
      },
      { flexId: '1', lable: 'birthday', lableTitle: 'Birthday', content: '' },
      {
        flexId: '1',
        lable: 'localExpat',
        lableTitle: 'Expat/Local',
        content: ''
      },
      { flexId: '1', lable: 'hfmCode', lableTitle: 'HFM Code', content: '' },
      { flexId: '1', lable: 'hcStatus', lableTitle: 'HC Status', content: '' },

      {
        flexId: '1',
        lable: 'replacementName',
        lableTitle: 'Replacement Name',
        content: ''
      },
      {
        flexId: '1',
        lable: 'legalEntityCn',
        lableTitle: 'Legal Entity In Chinese',
        content: ''
      },
      {
        flexId: '1',
        lable: 'legalEntityEn',
        lableTitle: 'Legal Entity In English',
        content: ''
      },
      {
        flexId: '1',
        lable: 'replacementLlid',
        lableTitle: 'Replacement LLID',
        content: ''
      },
      {
        flexId: '1',
        lable: 'rejoinEmployee',
        lableTitle: 'Rejoin Employee',
        content: ''
      },

      { flexId: '1', lable: 'csBuCode', lableTitle: 'CS BU Code', content: '' },
      {
        flexId: '1',
        lable: 'receivedPafTime',
        lableTitle: 'Received PAF Time',
        content: ''
      },
      {
        flexId: '1',
        lable: 'localEmployeeCode',
        lableTitle: 'Local Employee Code',
        content: ''
      },
      {
        flexId: '1',
        lable: 'specialRequirementForComputer',
        lableTitle: 'Computer Special Requirement',
        content: ''
      },
      {
        flexId: '1',
        lable: 'careerSettingId',
        lableTitle: 'Career Setting Id',
        content: ''
      },

      {
        flexId: '1',
        lable: 'massUploadDate',
        lableTitle: 'Mass Upload Date',
        content: ''
      },
      {
        flexId: '1',
        lable: 'businessUnitName',
        lableTitle: 'Business Unit Name',
        content: ''
      },
      {
        flexId: '1',
        lable: 'imacFinishedTime',
        lableTitle: 'Imac Finished Time',
        content: ''
      },
      { flexId: '1', lable: 'hrbpName', lableTitle: 'HRBP', content: '' },
      { flexId: '1', lable: 'joinDate', lableTitle: 'JoinDate', content: '' },

      {
        flexId: '1',
        lable: 'lionId',
        lableTitle: 'Lion ID Of employee',
        content: ''
      },
      {
        flexId: '1',
        lable: 'highestEducation',
        lableTitle: 'Highest Education',
        content: ''
      },
      {
        flexId: '1',
        lable: 'replacementNew',
        lableTitle: 'Replacement/New HC',
        content: ''
      },
      {
        flexId: '1',
        lable: 'criminalRecord',
        lableTitle: 'Criminal Offenses',
        content: ''
      },
      {
        flexId: '1',
        lable: 'operationContactor',
        lableTitle: 'Operation Contactor',
        content: ''
      },

      {
        flexId: '1',
        lable: 'recruitmentChannel',
        lableTitle: 'RecruitmentChannel',
        content: ''
      },
      {
        flexId: '1',
        lable: 'payrollContactor',
        lableTitle: 'PayrollContactor',
        content: ''
      }
    ]
  },
  {
    nameTitle: '户籍信息',
    nameKey: [
      {
        flexId: '1',
        lable: 'nativePlace',
        lableTitle: 'Native Place',
        content: ''
      },
      {
        flexId: '1',
        lable: 'permanentAddress',
        lableTitle: 'Permanent Address',
        content: ''
      },
      {
        flexId: '1',
        lable: 'residenceAddress',
        lableTitle: 'Current Residence Address',
        content: ''
      },
      {
        flexId: '1',
        lable: 'householdResidenceAddress',
        lableTitle: 'Household Residence Address',
        content: ''
      },
      {
        flexId: '2',
        lable: 'householdRegister',
        lableTitle: '戶口性质',
        content: ''
      }
    ]
  },
  {
    nameTitle: '联系信息',
    nameKey: [
      {
        flexId: '2',
        lable: 'mobileNumber',
        lableTitle: 'Mobile Number',
        content: ''
      },
      {
        flexId: '2',
        lable: 'personalMailAddress',
        lableTitle: 'Personal Mail Address',
        content: ''
      },
      {
        flexId: '2',
        lable: 'businessMail',
        lableTitle: 'Business Email',
        content: ''
      },
      { flexId: '2', lable: 'wechatId', lableTitle: 'WechatID', content: '' }
    ]
  },
  {
    nameTitle: '紧急联系人',
    nameKey: [
      { flexId: '1', lable: 'emergencyConcat', lableTitle: 'Name', content: '' },
      {
        flexId: '1',
        lable: 'emergencyPhone',
        lableTitle: 'Phone',
        content: ''
      },
      {
        flexId: '1',
        lable: 'emergencyRelation',
        lableTitle: 'Relation',
        content: ''
      },
      {
        flexId: '1',
        lable: '',
        lableTitle: '',
        content: ''
      }
    ]
  },
  { nameTitle: '阳狮集团任职亲属:', showButtonActive: '1a', tableKey: [] },
  { nameTitle: '未成年子女信息:', showButtonActive: '1b', tableKey: [] },
  {
    nameTitle: '财务信息&其它',
    nameKey: [
      { flexId: '1', lable: 'bankName', lableTitle: 'BankName', content: '' },
      { lable: 'beneficiaryName', lableTitle: 'Beneficiary Name', content: '' },
      { lable: 'bankAccount', lableTitle: 'Bank Account', content: '' },
      { lable: 'cigna', lableTitle: 'Cigna', content: '' },
      { lable: 'pingan', lableTitle: 'Pingan', content: '' },
      { lable: 'lastUpdateUser', lableTitle: 'Last Update User', content: '' },
      { lable: 'updateTime', lableTitle: 'Last Update Time', content: '' },
      {
        lable: 'commercialAgreement',
        lableTitle: 'commercial agreement',
        content: ''
      },
      { lable: 'remark', lableTitle: 'Remark', content: '' }
    ]
  }
]
// lable对应后端返回字段、前端名字对应lableTitle
// content 对应后端返回字段值
// flexId对应flex布局占几块
// typeId 对应是否是表格（1是表格、其他为表单排列）

export const basicStaffBasicInfoVoObj = {
  age: null,
  assigmentProjectLevel2: null,
  assigmentProjectLevel2Id: null,
  bankAccount: null,
  bankName: null,
  beneficiaryName: null,
  birthday: null,
  businessEmail: null,
  businessTitleInChinese: null,
  businessTitleInEnglish: null,
  businessUnitName: null,
  careerSettingId: null,
  childFunction: null,
  cigna: null,
  client: null,
  commercialAgreement: null,
  computerSpecialRequirement: null,
  criminalOffenses: null,
  csBuCode: null,
  currentResidenceAddress: null,
  descriptionLevel3: null,
  descriptionLevel3Id: null,
  emergencyName: null,
  emergencyPhone: null,
  emergencyRelation: null,
  englishName: null,
  ethnicity: null,
  expatOrLocal: null,
  gender: null,
  hcStatus: null,
  hfmCode: null,
  highestEducation: null,
  householdRegister: null,
  householdResidenceAddress: null,
  hrbp: null,
  hrbpId: null,
  iMacFinishedTime: null,
  idNumber: null,
  imacFinishedTime: null,
  immediate: null,
  jobGrade: null,
  joinDate: null,
  lastUpdateTime: null,
  lastUpdateUser: null,
  legalEntityInChinese: null,
  legalEntityInEnglish: null,
  lineManagerLlid: null,
  lionIdOfEmployee: null,
  localEmployeeCode: null,
  localName: null,
  massUploadDate: null,
  mobileNumber: null,
  nation: null,
  nationality: null,
  nativePlace: null,
  operationContractor: null,
  operationContractorId: null,
  parentFunction: null,
  payrollContractor: null,
  payrollContractorId: null,
  permanentPlace: null,
  personalMailAddress: null,
  pingan: null,
  receivedPafTime: null,
  recruitmentChannel: null,
  rejoinEmployee: null,
  relationAppellation: null,
  relationName: null,
  remark: null,
  replacementLlid: null,
  replacementName: null,
  replacementOrNewHc: null,
  updateUser: null,
  weChatId: null
}
export const basicChildObj = {
  childName: null,
  id: null,
  idNumber: null,
  idType: null,
  isInsure: null,
  offerProposalId: null,
  birthday: null,
  outerId: null,
  syncStatus: null
}

export const baseDataList = baseData
