export const salaryData = {
  nameTitle: '',
  nameKey: [
    {
      flexId: '1',
      lable: 'monthlyGrossBaseSalary',
      lableTitle: 'Monthly Gross Base Salary',
      content: ''
    },
    {
      flexId: '1',
      lable: 'postProbationMonthlyGrossSalary',
      lableTitle: 'Post-probation Monthly Gross Salary',
      content: ''
    },
    {
      flexId: '1',
      lable: 'thirteenSalary',
      lableTitle: '13 Month Salary',
      content: ''
    },
    // {
    //   flexId: '1',
    //   lable: 'annualLeaveDays',
    //   lableTitle: 'Annual Leave Days',
    //   content: ''
    // },
    {
      flexId: '1',
      lable: 'allowanceTotalPackageForForeigner',
      lableTitle: 'Allowance Total Package for foreigner(CNY)',
      content: ''
    },
    { flexId: '1', lable: 'homeTrip', lableTitle: 'Home Trip', content: '' },
    {
      flexId: '1',
      lable: 'targetBouns',
      lableTitle: 'Target Bonus%',
      content: ''
    },

    {
      flexId: '1',
      lable: `annualEducationAllowanceCny`,
      lableTitle: 'Monthly Education Allowance(CNY)',
      content: ''
    },
    {
      flexId: '1',
      lable: 'annualMonthlyHousingAllowance',
      lableTitle: 'Monthly Housing Allowance(CNY)',
      content: ''
    },
    {
      flexId: '1',
      lable: 'annualLivingAllowance',
      lableTitle: 'Monthly Living Allowance(CNY)',
      content: ''
    },

    {
      flexId: '1',
      lable: 'annualOtherAllowance',
      lableTitle: 'Monthly Other Allowance',
      content: ''
    },
    {
      flexId: '1',
      lable: 'relocationAnnualAllowance',
      lableTitle: 'Relocation Allowance',
      content: ''
    },
    {
      flexId: '1',
      lable: 'annualEducationAllowance',
      lableTitle: 'Annual Education Allowance',
      content: ''
    },
    {
      flexId: '1',
      lable: 'repatriationAllowance',
      lableTitle: 'Repatriation Allowance',
      content: ''
    },
    {
      flexId: '1',
      lable: 'otherBenefits',
      lableTitle: 'OtherBenefits',
      content: ''
    },
    {
      flexId: '1',
      lable: 'annualPackage',
      lableTitle: 'Annual Package',
      content: ''
    },
    {
      flexId: '1',
      lable: 'totalAnnualAllowance',
      lableTitle: 'Monthly Allowance',
      content: ''
    },
    {
      flexId: '1',
      lable: 'annualAllowance',
      lableTitle: 'Annual Allowance',
      content: ''
    },
    { flexId: '1', lable: 'currency', lableTitle: 'Currency', content: '' },
    { flexId: '1', lable: 'id', lableTitle: 'id', content: '' },
    // 不知道问什么会多了这两个，上一位前端写的
    // { flexId: '1', lable: '', lableTitle: '', content: '' },
    // { flexId: '1', lable: '', lableTitle: '', content: '' }
  ]
}
// lable对应后端返回字段、前端名字对应lableTitle
// content 对应后端返回字段值
// flexId对应flex布局占几块
// typeId 对应是否是表格（1是表格、其他为表单排列）

export const ApiDataObj = {
  annualEducationAllowance: null,
  // annualLeaveDays: null,
  annualMonthlyHousingAllowance: null,
  annualOtherAllowance: null,
  annualTotalSalary: null,
  bonusEligible: null,
  candidateInformationId: null,
  careerSettingId: null,
  contractualBonusPlan: null,
  currency: null,
  discretionaryBonusPlan: null,
  discretionaryTargetBonusPer: null,
  endDate: null,
  homeTrip: null,
  id: null,
  idNumber: null,
  isEligibleForLti: null,
  ltiPlan: null,
  monthlyGrossBaseSalary: null,
  otherBenefits: null,
  postProbationMonthlyGrossSalary: null,
  relocationAnnualAllowance: null,
  startDate: null,
  status: null,
  targetBouns: null,
  thirteenSalary: null,
  totalAnnualAllowance: null
}
