var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"editInduction-list-page"},[_c('PocCardTable',{staticClass:"irisePocCardTable",scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('span',{staticStyle:{"font-weight":"400"}},[_c('span',{staticClass:"personTitle"},[_vm._v("员工管理")]),_vm._v(" / 员工信息 "),_c('span',{staticStyle:{"font-size":"18px","margin-left":"6px","color":"#868686"}},[_vm._v(" - "+_vm._s(_vm.englishName)+" / "+_vm._s(_vm.chineseName))])])]},proxy:true}])},[_c('div',{staticClass:"editContainer"},[[_c('el-tabs',{staticStyle:{"margin-top":"16px"},on:{"tab-click":_vm.changeButton},model:{value:(_vm.buttonActive),callback:function ($$v) {_vm.buttonActive=$$v},expression:"buttonActive"}},[_c('el-tab-pane',{attrs:{"label":"基本信息","name":"1"}}),_c('el-tab-pane',{attrs:{"label":"职位信息","name":"2"}}),_c('el-tab-pane',{attrs:{"label":"合同信息","name":"3"}}),_c('el-tab-pane',{attrs:{"label":"薪水信息","name":"4"}}),_c('el-tab-pane',{attrs:{"label":"教育信息","name":"5"}}),_c('el-tab-pane',{attrs:{"label":"工作经历","name":"6"}}),_c('el-tab-pane',{attrs:{"label":"离职信息","name":"7"}}),_c('el-tab-pane',{attrs:{"label":"附件信息","name":"8"}}),(_vm.backMsgShow)?_c('el-tab-pane',{attrs:{"label":"背调报告","name":"9"}}):_vm._e()],1),(
              _vm.buttonActive == '1' ||
              _vm.buttonActive == '2' ||
              _vm.buttonActive == '3' ||
              _vm.buttonActive == '4' ||
              _vm.buttonActive == '7'
            )?_c('employee-show-manage',{ref:"employeeShowManageRef",attrs:{"baseData":_vm.baseData,"candidateId":_vm.candidateInformationId,"buttonActive":_vm.buttonActive,"ceoId":_vm.ceo},on:{"goback":_vm.goback,"getSalarys":_vm.getSalarys,"getPosition":_vm.getPosition}}):_vm._e(),(_vm.buttonActive == '5' || _vm.buttonActive == '6')?_c('employee-table-manage',{attrs:{"buttonActive":_vm.buttonActive,"candidateInformationId":_vm.candidateInformationId,"idNumber":_vm.idNumber},on:{"goback":_vm.goback}}):_vm._e(),(_vm.buttonActive == '8')?_c('UploadTableDisplay',{staticStyle:{"margin-top":"20px"},attrs:{"fromFlag":'detail',"jumpParams":_vm.jumpParams}}):_vm._e(),(_vm.buttonActive == '9')?_c('BackMsgTable',{attrs:{"fromFlag":'detail',"jumpParams":_vm.jumpParams}}):_vm._e()]],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }