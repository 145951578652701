<template>
  <div class="UploadTableDisplay">
    <PocTable
      v-loading="queryLoading"
      :data="tableData"
      stripe
      :max-height="100000000"
      ref="UploadTableDisplayTable"
      hidepage
      row-key="id"
    >
      <el-table-column
        v-for="item in columnOptions"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :sortable="item.sortable"
        :width="item.width"
        align="left"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        key="attachmentName"
        prop="attachmentName"
        label="附件名称"
        :fixed="false"
        width="200"
        align="left"
      >
        <template slot-scope="scope">
          <attachmentMapListDisplay
            :attachment-list="scope.row.attachmentName"
          />
        </template>
      </el-table-column>
    </PocTable>
  </div>
</template>

<script>
import attachmentMapListDisplay from '@/views/employee/components/attachmentMapListDisplay'
export default {
  name: 'UploadTableDisplay',
  components: {
    attachmentMapListDisplay
  },
  data() {
    return {
      queryLoading: false,
      tableData: [
        {
          fileType: '最高学历证明',
          attachmentName: [],
          //候选人最高学历证明
          fileKey: 'educationGraduationCertificate'
        },
        {
          fileType: '学历证书',
          attachmentName: [],
          //候选人最高学位证书
          fileKey: 'degreeCertificate'
        },
        {
          fileType: '简历',
          attachmentName: [],
          //候选人简历
          fileKey: 'resume'
        },
        {
          fileType: '回签Offer',
          attachmentName: [],
          //候选人已经签署的录取通知书
          fileKey: 'signedOfferLetter'
        },

        {
          fileType: '身份证复印件',
          attachmentName: [],
          //候选人身份证复印件
          fileKey: 'idCard'
        },
        {
          fileType: '户口本复印件',
          attachmentName: [],
          //候选人户口本复印件
          fileKey: 'residenceDocument'
        },
        {
          fileType: '子女证件复印件',
          attachmentName: [],
          //子女出生证或户口本复印件
          fileKey: 'childrenIdCard'
        },
        {
          fileType: '银行卡',
          attachmentName: [],
          //候选人中国银行银行卡复印件
          fileKey: 'bank'
        },

        {
          fileType: '体检报告',
          attachmentName: [],
          //候选人体检报告
          fileKey: 'medicalReport'
        }
      ],
      columnOptions: [
        {
          key: 'fileType',
          name: '文件类型',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: 200 // 默认宽度，像素
        }
        // {
        //   key: 'attachmentName',
        //   name: '附件名称',
        //   dataType: 'string',
        //   visible: true,
        //   sortable: false,
        //   fixed: false,
        //   width: 200
        // }
      ]
    }
  },
  props: {
    fromFlag: {
      type: String
    },
    jumpParams: {
      type: Object
    }
  },
  created() {
    this.getUploadList()
  },
  methods: {
    async getUploadList() {
      const data = await this.$Api.candidate.getuploadList({
        id: this.jumpParams.candidateInformationId
      })
      if (data && data.code === 200) {
        this.tableData.forEach((item) => {
          item.attachmentName = data.data[item.fileKey]
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.UploadTableDisplay {
}
</style>
