export const positionDataObj = {
  nameTitle: '',
  nameKey: [
    {
      flexId: '1',
      lable: 'businessTitleInEnglish',
      lableTitle: 'Business Title in English',
      content: ''
    },
    {
      flexId: '1',
      lable: 'businessTitleInChinese',
      lableTitle: 'Business Title in Chinese',
      content: ''
    },
    { flexId: '1', lable: 'laborCode', lableTitle: 'Labor Code', content: '' },
    {
      flexId: '1',
      lable: 'assigmentProjectLevel2',
      lableTitle: 'Internal Brand',
      content: ''
    },
    { flexId: '1', lable: 'buName', lableTitle: 'Brand BU', content: '' },
    { flexId: '1', lable: 'department', lableTitle: 'Department', content: '' },

    {
      flexId: '1',
      lable: 'descriptionLevel3',
      lableTitle: 'Department/Team',
      content: ''
    },

    {
      flexId: '1',
      lable: 'workSchedules',
      lableTitle: 'Work Schedules',
      content: ''
    },
    { flexId: '1', lable: 'jobGrade', lableTitle: 'Job Grade', content: '' },
    {
      flexId: '1',
      lable: 'parentFunction',
      lableTitle: 'Parent Function',
      content: ''
    },
    {
      flexId: '1',
      lable: 'childFunction',
      lableTitle: 'Child Function',
      content: ''
    },
    {
      flexId: '1',
      lable: 'recruitmentChannelId',
      lableTitle: 'Recruitment Channel',
      content: ''
    },
    {
      flexId: '1',
      lable: 'lineManagerLlid',
      lableTitle: 'Lion ID of LM',
      content: ''
    },
    {
      flexId: '1',
      lable: 'lineManagerName',
      lableTitle: 'Name of LM',
      content: ''
    },
    // {
    //   flexId: '1',
    //   lable: 'lineManagerEmail',
    //   lableTitle: 'LM Email',
    //   content: ''
    // },
    // {
    //   flexId: '1',
    //   lable: 'nameOfPeopleManager',
    //   lableTitle: 'Name of People+Manager1',
    //   content: ''
    // },
    // {
    //   flexId: '1',
    //   lable: 'nameOfVegaLm',
    //   lableTitle: 'Name of Vega LM',
    //   content: ''
    // },
    // {
    //   flexId: '1',
    //   lable: 'lionIdOfLmVega',
    //   lableTitle: 'Lion ID of LM Vega ',
    //   content: ''
    // },
    // {
    //   flexId: '1',
    //   lable: 'llidOfPeopleManager',
    //   lableTitle: 'LLID of People+Manager1',
    //   content: ''
    // },
    {
      flexId: '1',
      lable: 'lionIdOfPerformanceManager',
      lableTitle: 'Lion ID of Performance Manager',
      content: ''
    },
    {
      flexId: '1',
      lable: 'nameOfPerformanceManager',
      lableTitle: 'Name of Performance Manager',
      content: ''
    },
    { flexId: '1', lable: 'ceoName', lableTitle: 'CEO', content: '' },
    { flexId: '1', lable: 'ceoName1', lableTitle: 'CEO-1', content: '' },
    { flexId: '1', lable: 'client', lableTitle: 'Client', content: '' },
    { flexId: '1', lable: 'startDate', lableTitle: 'startDate', content: '' },
    {
      flexId: '1',
      lable: '',
      lableTitle: '',
      content: ''
    }
  ]
}
// lable对应后端返回字段、前端名字对应lableTitle
// content 对应后端返回字段值
// flexId对应flex布局占几块
// typeId 对应是否是表格（1是表格、其他为表单排列）

export const positionApiObj = {
  assigmentProjectLevel2: null,
  buName: null,
  businessTitleInChinese: null,
  businessTitleInEnglish: null,
  candidateInformationId: null,
  childFunction: null,
  client: null,
  department: null,
  descriptionLevel3: null,
  endDate: null,
  hfmCode: null,
  hrManager: null,
  hrManagerLlid: null,
  id: null,
  idNumber: null,
  jobGrade: null,
  laborCode: null,
  lineManagerLlid: null,
  lineManagerName: null,
  lionIdOfLmVega: null,
  llidOfPeopleManager: null,
  nameOfPeopleManager: null,
  nameOfVegaLm: null,
  parentFunction: null,
  startDate: null,
  status: null,
  workSchedules: null,
  workSchedulesEn: null
}
