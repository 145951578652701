<template>
  <div style="margin-top: 8px">
    <div class="flexBet" style="margin-bottom: 8px">
      <div></div>
      <el-button
        size="mini"
        role="showBack"
        class="narrowButtonText"
        @click="showBackMsg"
      >查看背调报告</el-button>
    </div>
    <PocTable
      v-loading="queryLoading"
      :data="backInfoMsgList"
      stripe
      :max-height="100000000"
      ref="BackMsgTableRef"
      hidepage
      row-key="id"
    >
      <el-table-column
        v-for="item in columnOptions"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :width="item.width"
        align="left"
        show-overflow-tooltip
      >
        <template v-slot:header>
          <div class="center">{{ item.nameZn }}</div>
<!--          <div class="center">{{ item.name }}</div>-->
        </template>
      </el-table-column>
    </PocTable>
  </div>
</template>

<script>
export default {
  name: "BackMsgTable",
  props: {
    jumpParams: {
      type: Object
    }
  },
  data(){
    return{
      queryLoading: false,
      backInfoMsgList:[],
      columnOptions:[
        {
          key: 'periodStart',
          name: 'Period Start',
          nameZn: '工作起始',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: 'sortable', // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: null // 默认宽度，像素
        },
        {
          key: 'periodEnd',
          name: 'Period End',
          nameZn: '工作截止',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'companyName',
          name: 'Company',
          nameZn: '公司名称',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'lastJobTitle',
          name: 'Job Title',
          nameZn: '离职前职位',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
        {
          key: 'leaveReason',
          name: 'Leave Reason',
          nameZn: '离职原因',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null
        },
      ],
      tabNameArr:[],
    }
  },
  mounted() {
    this.getBackInfo(this.jumpParams.candidateInformationId)
  },
  methods:{
    async getBackInfo(id) {
      const { data, code, msg } = await this.$Api.candidate.employmentHistoryList({
        id: id
      })
      if (code === 200) {
        this.backInfoMsgList = data || []
        this.backInfoMsgList.map(item=>{
          if(item.leaveReason=== "1"){
            item.leaveReason = "主动离职"
          }else if(item.leaveReason=== "2") {
            item.leaveReason = "被动离职"
          }
          return item
        })
      }
    },
    showBackMsg(){
      if(this.backInfoMsgList.length === 0){
        this.$message.info("该员工暂无背调报告")
        return
      }
      let page = this.$router.resolve({
        path: '/candidateManage/backMsg',
        query: {
          offerProposalId: this.jumpParams.candidateInformationId
        }
      })
      window.open(page.href, '_blank')
    },
  }
};
</script>

<style lang="scss" scoped>
.narrowButtonText{
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
</style>
