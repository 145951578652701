<template>
  <el-dialog
    :visible="dialogVisibleEducation"
    title="Tips"
    width="35%"
    append-to-body
    custom-class="EducationalQualificationsDialog"
    :before-close="close"
  >
    <el-form
      ref="ruleEduFormRef"
      :model="ruleForm"
      :rules="rules"
      inline
      label-position="top"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :lg="12">
          <el-form-item label="学历Education" prop="degreeType">
            <el-select
              v-model="ruleForm.degreeType"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in educationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="修业状况Status" prop="studyStatus">
            <el-radio-group v-model="ruleForm.studyStatus">
              <el-radio label="Certificate of Completion"
                >Certificate of Completion</el-radio
              >
              <el-radio label="Certificate of Incompletion"
                >Certificate of Incompletion</el-radio
              >
              <el-radio label="Pursuing Study">Pursuing Study</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12">
          <el-form-item label="学校名称Name of School" prop="school">
            <el-input v-model="ruleForm.school"></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="专业Major" prop="major">
            <el-input v-model="ruleForm.major"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12">
          <el-form-item label="起 From" prop="startDate">
            <el-date-picker
              v-model="ruleForm.startDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="Pick a day"
              size="small"
            />
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="迄 To" prop="endDate">
            <el-date-picker
              v-model="ruleForm.endDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="Pick a day"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12">
          <el-checkbox
            v-model="ruleForm.degreeObtained"
            label="取得相应学位 Corresponding Degree Obtained"
            size="large"
          />
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">Cancel</el-button>
        <el-button type="primary" @click="confirm">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { rules } from './rules'
export default {
  name: 'employeeJoin',
  mixins: [$PCommon.TableMixin],
  components: {},
  watch: {
    rowItem(newValue, oldValue) {
      this.ruleForm = newValue
    }
  },
  props: {
    dialogVisibleEducation: {
      type: Boolean
    },
    rowItem: {
      type: Object
    }
  },
  data() {
    return {
      queryLoading: false,
      educationList: [
        { value: '学士', label: '学士' },
        { value: '硕士', label: '硕士' },
        { value: '博士', label: '博士' }
      ],
      rules: rules,

      dialogVisible: true,
      ruleForm: {
        degreeType: '',
        studyStatus: '',
        school: '',
        major: '',
        startDate: '',
        endDate: '',
        degreeObtained: false
      }
    }
  },
  computed: {},

  created() {},
  methods: {
    confirm() {
      this.$refs.ruleEduFormRef.validate((valid) => {
        console.log('校验成功失败', valid)
        if (valid) {
          this.$emit('confirm')
        } else {
          return
        }
      })
    },
    close() {
      this.$emit('close')
    }
  },
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
.EducationalQualificationsDialog {
  .el-dialog__body {
    padding: 0;
  }
}
</style>
