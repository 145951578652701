<template>
  <div class="editInduction-list-page" v-loading="loading">
    <PocCardTable class="irisePocCardTable">
      <template v-slot:headerTitle>
        <span style="font-weight: 400"><span class="personTitle">员工管理</span> / 员工信息
          <span style="font-size: 18px;margin-left: 6px;color: #868686;"> - {{englishName}} / {{chineseName}}</span>
        </span>
      </template>
<!--      <template v-slot:headerGroup>-->
<!--        <el-button @click="goback">返回</el-button>-->
<!--      </template>-->
      <div class="editContainer">
        <template>
          <el-tabs
            style="margin-top: 16px"
            v-model="buttonActive"
            @tab-click="changeButton"
          >
            <el-tab-pane label="基本信息" name="1"></el-tab-pane>
            <el-tab-pane label="职位信息" name="2"></el-tab-pane>
            <el-tab-pane label="合同信息" name="3"></el-tab-pane>
            <el-tab-pane label="薪水信息" name="4"></el-tab-pane>
            <el-tab-pane label="教育信息" name="5"></el-tab-pane>
            <el-tab-pane label="工作经历" name="6"></el-tab-pane>
            <el-tab-pane label="离职信息" name="7"></el-tab-pane>
            <el-tab-pane label="附件信息" name="8"></el-tab-pane>
<!--            权限-->
            <el-tab-pane label="背调报告" name="9" v-if="backMsgShow"></el-tab-pane>
          </el-tabs>
          <employee-show-manage
            v-if="
              buttonActive == '1' ||
              buttonActive == '2' ||
              buttonActive == '3' ||
              buttonActive == '4' ||
              buttonActive == '7'
            "
            :baseData="baseData"
            :candidateId="candidateInformationId"
            :buttonActive="buttonActive"
            :ceoId="ceo"
            @goback="goback"
            @getSalarys="getSalarys"
            @getPosition="getPosition"
            ref="employeeShowManageRef"
          />
          <!-- 表格展示区域 5，6-->
          <employee-table-manage
            v-if="buttonActive == '5' || buttonActive == '6'"
            :buttonActive="buttonActive"
            :candidateInformationId="candidateInformationId"
            :idNumber="idNumber"
            @goback="goback"
          />
          <!-- 上传区域 8-->
          <!--          <employee-upload-manage-->
          <!--            v-if="buttonActive == '8'"-->
          <!--            style="margin-top: 20px"-->
          <!--            :fromFlag="'detail'"-->
          <!--            :jumpParams="jumpParams"-->
          <!--            @goback="goback"-->
          <!--          />-->
          <UploadTableDisplay
            v-if="buttonActive == '8'"
            style="margin-top: 20px"
            :fromFlag="'detail'"
            :jumpParams="jumpParams"
          />
          <BackMsgTable v-if="buttonActive == '9'" :fromFlag="'detail'" :jumpParams="jumpParams"/>
        </template>
      </div>
    </PocCardTable>
  </div>
</template>

<script>
import store from "@/store";
import {
  baseDataList,
  basicChildObj,
  basicStaffBasicInfoVoObj
} from "@/views/employee/components/detailMsgManage/baseData";
import { positionApiObj, positionDataObj } from "@/views/employee/components/detailMsgManage/positionData";
import { agreementDataObj, ConcatApiObj } from "@/views/employee/components/detailMsgManage/agreementData";
import { ApiDataObj, salaryData } from "@/views/employee/components/detailMsgManage/salaryData";
import { resignDataList } from "@/views/employee/components/detailMsgManage/resignData";

import employeeShowManage from "./detailMsgManage/employeeShowManage.vue";
import employeeTableManage from "./detailMsgManage/employeeTableManage.vue";
import employeeUploadManage from "@/views/employee/components/uploadManage.vue";
import UploadTableDisplay from "@/views/employee/components/UploadTableDisplay";
import { cloneDeep } from "lodash";
import BackMsgTable from "@/views/employee/components/BackMsgTable";
import md5 from "md5";
import { getItem } from "@/utils/storage";

const { Vuex } = $PCommon
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    employeeShowManage,
    employeeTableManage,
    employeeUploadManage,
    UploadTableDisplay,
    BackMsgTable
  },
  data() {
    return {
      buttonActive: '1',
      backMsgShow: false,
      buttonData: {
        1: '基本信息',
        2: '职位信息',
        3: '合同信息',
        4: '薪水信息',
        5: '教育信息', //表格
        6: '工作经历', //表格
        7: '离职信息',
        8: '附件信息' //上传文件
      },
      baseTitleArr: [
        '基础信息',
        '财务信息&其它',
        '联系信息',
        '紧急联系人',
        '阳狮集团任职亲属:',
        '未成年子女信息:',
        '户籍信息'
      ],
      positionTitleArr: [],
      agreeTitleArr: [
        '2019/06/10-至今',
        '2019/06/10-2022/06/09',
        '2017/06/10-2019/06/10'
      ],
      salaryTitleArr: [
        '2019/06/10-至今',
        '2019/06/10-2022/06/09',
        '2017/06/10-2019/06/10'
      ],
      baseDataList: baseDataList,
      basicChildObj: basicChildObj,
      basicStaffBasicInfoVoObj: basicStaffBasicInfoVoObj,

      positionDataList: [],
      positionObj: positionDataObj,
      positionApiObj: positionApiObj,
      agreementDataList: [],
      agreementDataObj: agreementDataObj,
      ConcatApiObj: ConcatApiObj,
      salaryObj: salaryData,
      salaryApidataObj: ApiDataObj,
      salaryDataList: [],
      resignDataList: resignDataList,
      baseData: [],
      getHcInfoData: [],
      candidateInformationId: '',
      idNumber: '',
      props: { multiple: true },
      jumpParams: {},
      ceo:'',
      loading:false,
      englishName:'',
      chineseName:'',
    }
  },
  computed: {},
  created() {
    let jumpParams = this.$route.query.jumpParams
    console.log('jumpParams',jumpParams);
    if (jumpParams) {
      this.jumpParams = JSON.parse(jumpParams)
      this.candidateInformationId = this.jumpParams.candidateInformationId
      this.idNumber = this.jumpParams.idNumber
      if(this.jumpParams.userName !== md5(getItem("userName"))){
        this.loading = true
        this.$message.error("您没有权限查看该员工信息！")
        return
      }
      this.getBasicMsg(this.candidateInformationId, '1')
    }
    const points = store.getters.point
    if(points){
      this.backMsgShow = points.some((point) => {
        return point.menuCode === 'backMsgShow'
      })
    }
  },
  methods: {
    getSalarys() {
      console.log('aaa------')
      this.getSalaryMsg(this.candidateInformationId, this.buttonActive)
    },
    getPosition() {
      console.log('aaa------')
      this.getPositionMsg(this.candidateInformationId, this.buttonActive)
    },
    async getBasicMsg(id, dataType) {
      const { data, code, msg } = await this.$Api.staff.getBascMsg({
        id: id
      })
      if (code === 200) {
        if (data.childrenList && !data.childrenList.length) {
          data.childrenList.push(this.basicChildObj)
        }
        if (!data.staffBasicInfoVo) {
          data.staffBasicInfoVo = this.basicStaffBasicInfoVoObj
        }
        this.englishName = data.staffBasicInfoVo.englishName
        this.chineseName = data.staffBasicInfoVo.chineseName
        let familyArr = [
          {
            immediate: data.staffBasicInfoVo
              ? data.staffBasicInfoVo.immediate
                ? data.staffBasicInfoVo.immediate
                : ''
              : '',
            relationName: data.staffBasicInfoVo.relationName
              ? data.staffBasicInfoVo.relationName
              : '',
            relationAppellation: data.staffBasicInfoVo.relationAppellation
              ? data.staffBasicInfoVo.relationAppellation
              : ''
          }
        ]
        data['未成年子女信息:'] = data.childrenInfoWithFileList
        data['阳狮集团任职亲属:'] = familyArr
        this.getHcInfoData = data
        // 试用期/绩效考核期内title与试用期/绩效考核期后title不一致时
        if(this.getHcInfoData.staffBasicInfoVo.businessTitleEn !== this.getHcInfoData.staffBasicInfoVo.businessTitleInEnglishDuringProbation){
          if(this.baseDataList[0].nameKey[2].lable !== 'businessTitleInEnglishDuringProbation'){
            let title = ["3","6"].includes(this.getHcInfoData.staffBasicInfoVo.offerTemplateId) ? 'assessment':'probation'
            this.baseDataList[0].nameKey.splice(2,0,
              {
                flexId: '1',
                lable: 'businessTitleInEnglishDuringProbation',
                lableTitle: `Business Title in English (during ${title})`,
                content: ''
              },{
                flexId: '1',
                lable: 'businessTitleInChineseDuringProbation',
                lableTitle: `Business Title in Chinese (during ${title})`,
                content: ''
              })
          }
        }else {
          if(this.baseDataList[0].nameKey[2].lable === 'businessTitleInEnglishDuringProbation'){
            this.baseDataList[0].nameKey.splice(2,2)
          }
        }
        this.initData(this.baseDataList, this.getHcInfoData, this.baseTitleArr)
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    async getPositionMsg(candidateInformationId, dataType) {
      let assigmentProjectLevel2Id
      let descriptionLevel3Id
      let positionId
      const { data, code, msg } = await this.$Api.staff.getPositionMsg({
        id: candidateInformationId
      })
      if (code === 200) {
        console.log('data--------data', data, this.positionApiObj)
        if (!data.length) {
          data.push(this.positionApiObj)
        }else {
          this.ceo = data[0].ceo
          console.log(data[0].ceo);
        }

        this.positionDataList = []
        data.map((d, index) => {
          d.endDate = d.endDate
            ? d.endDate.slice(0, 10).replace('-', '/')
            : '至今'
          d.startDate = d.startDate
            ? d.startDate.slice(0, 10).replace('-', '/')
            : '未知'
          d.DataTitle = d.startDate + '-' + d.endDate

          this.positionTitleArr.push(d.DataTitle)

          let positionDataObj = {}
          positionDataObj.nameTitle = d.DataTitle
          positionDataObj.nameKey = cloneDeep(this.positionObj.nameKey)
          this.positionDataList.push(positionDataObj)

          let infoDataObj = { nameTitle: '', nameKey: '' }
          infoDataObj.nameTitle = d.DataTitle
          infoDataObj.nameKey = d
          this.getHcInfoData.push(infoDataObj)
          assigmentProjectLevel2Id = d.assigmentProjectLevel2Id
          descriptionLevel3Id = d.descriptionLevel3Id
          positionId = d.id
        })
        // 试用期/绩效考核期内title与试用期/绩效考核期后title不一致时
        if(this.getHcInfoData[0].nameKey.businessTitleInEnglish !== this.getHcInfoData[0].nameKey.businessTitleInEnglishDuringProbation){
          if(this.positionDataList[0].nameKey[2].lable !== 'businessTitleInEnglishDuringProbation'){
            let title = ["3","6"].includes(this.getHcInfoData[0].nameKey.offerTemplateId) ? 'assessment':'probation'
            this.positionDataList[0].nameKey.splice(2,0,
              {
                flexId: '1',
                lable: 'businessTitleInEnglishDuringProbation',
                lableTitle: `Business Title in English (during ${title})`,
                content: ''
              },{
                flexId: '1',
                lable: 'businessTitleInChineseDuringProbation',
                lableTitle: `Business Title in Chinese (during ${title})`,
                content: ''
              })
          }
        }else {
          if(this.positionDataList[0].nameKey[2].lable === 'businessTitleInEnglishDuringProbation'){
            this.positionDataList[0].nameKey.splice(2,2)
          }
        }


        console.log(
          '-------------------------',
          this.positionDataList,
          this.getHcInfoData,
          this.positionTitleArr
        )
        this.initData(
          this.positionDataList,
          this.getHcInfoData,
          this.positionTitleArr
        )
        await this.$refs.employeeShowManageRef.getFromOption(assigmentProjectLevel2Id,descriptionLevel3Id,candidateInformationId,positionId)
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    async getContactMsg(candidateInformationId, dataType) {
      const { data, code, msg } = await this.$Api.staff.getContactMsg({
        id: candidateInformationId
      })
      if (code === 200) {
        if (!data.length) {
          data.push(this.ConcatApiObj)
        }
        data.map((d, index) => {
          d.endDate = d.endDate
            ? d.endDate.slice(0, 10).replace('-', '/')
            : '至今'
          d.startDate = d.startDate
            ? d.startDate.slice(0, 10).replace('-', '/')
            : '未知' + index
          d.DataTitle = d.startDate + '-' + d.endDate

          this.agreeTitleArr.push(d.DataTitle)

          let contactDataObj = {}
          contactDataObj.nameTitle = d.DataTitle
          contactDataObj.nameKey = cloneDeep(this.agreementDataObj.nameKey)
          this.agreementDataList.push(contactDataObj)

          let infoDataObj = { nameTitle: '', nameKey: '' }
          infoDataObj.nameTitle = d.DataTitle
          infoDataObj.nameKey = d
          this.getHcInfoData.push(infoDataObj)
        })
        let title = ["3","6"].includes(this.getHcInfoData[0].nameKey.offerTemplateId) ? 'assessment':'probation'
        let title2 = ["3","6"].includes(this.getHcInfoData[0].nameKey.offerTemplateId) ? 'Assessment':'Probation'
        this.agreementDataList[0].nameKey[7].lableTitle = `${title2} Period（Months)`
        this.agreementDataList[0].nameKey[8].lableTitle = `${title2} Period End Date`
        this.agreementDataList[0].nameKey[10].lableTitle = `Notice Period Days(within ${title})`
        this.initData(
          this.agreementDataList,
          this.getHcInfoData,
          this.agreeTitleArr
        )
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    async getSalaryMsg(candidateInformationId, dataType) {
      // 清空之前的数据
      this.salaryDataList = []
      const { data, code, msg } = await this.$Api.staff.getSalaryMsg({
        id: candidateInformationId
      })
      console.log('data', data, this.salaryApidataObj)
      if (code === 200) {
        if (!data.length) {
          //接口未返回任何数组时，页面显示的处理
          data.push(this.salaryApidataObj)
        }
        data.map((d, index) => {
          d.endDate = d.endDate
            ? d.endDate.slice(0, 10).replace('-', '/')
            : '至今'
          d.startDate = d.startDate
            ? d.startDate.slice(0, 10).replace('-', '/')
            : '未知' + index
          d.DataTitle = d.startDate + '-' + d.endDate
          this.salaryTitleArr.push(d.DataTitle)
          let salaryDataObj = {}
          salaryDataObj.nameTitle = d.DataTitle
          salaryDataObj.nameKey = cloneDeep(this.salaryObj.nameKey)

          this.salaryDataList.push(salaryDataObj)
          let abj = {
            nameTitle: '五险一金基数',
            nameKey: [
              {
                content: data[0].endowment,
                flexId: '1',
                lable: 'endowment',
                lableTitle: '养老基数'
              },
              {
                content: data[0].medical,
                flexId: '1',
                lable: 'medical',
                lableTitle: '医疗基数'
              },
              {
                content: data[0].unemployment,
                flexId: '1',
                lable: 'unemployment',
                lableTitle: '失业基数'
              },
              {
                content: data[0].employmentInjury,
                flexId: '1',
                lable: 'employmentInjury',
                lableTitle: '工伤基数'
              },
              {
                content: data[0].maternity,
                flexId: '1',
                lable: 'maternity',
                lableTitle: '生育基数'
              },
              {
                content: data[0].providentFund,
                flexId: '1',
                lable: 'providentFund',
                lableTitle: '公积金基数'
              }
            ]
          }
          this.salaryDataList.push(abj)

          let infoDataObj = { nameTitle: '', nameKey: '' }
          infoDataObj.nameTitle = d.DataTitle
          // d.annualEducationAllowanceCny = (Number(d.annualEducationAllowance) / 12).toFixed(2)
          infoDataObj.nameKey = d
          this.getHcInfoData.push(infoDataObj)
        })
        let title = ["3","6"].includes(this.getHcInfoData[0].nameKey.offerTemplateId) ? 'assessment':'probation'
        this.salaryDataList[0].nameKey[1].lableTitle = `Post-${title} Monthly Gross Salary`
        console.log(
          '==============*******',
          this.salaryDataList,
          this.salaryTitleArr
        )
        this.initData(
          this.salaryDataList,
          this.getHcInfoData,
          this.salaryTitleArr
        )
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    async getLeaveMsg(candidateInformationId, dataType) {
      const { data, code, msg } = await this.$Api.staff.getLeaveMsg({
        id: candidateInformationId
      })
      if (code === 200) {
        this.getHcInfoData = data
        // data.lastWorkingDate = ''
        console.log('==========', this.resignDataList)
        this.initData(this.resignDataList, this.getHcInfoData, '')
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    initData(deailDataList, getHcInfoData, titleArr) {
      console.log('--------',deailDataList);
      if (titleArr) {
        deailDataList.map((d) => {
          titleArr.map((v, index) => {
            if (v == d.nameTitle) {
              if (!d.hasOwnProperty('tableKey')) {
                d.nameKey.map((item) => {
                  if (this.buttonActive == '1') {
                    if (v == '基础信息') {
                      item.content = getHcInfoData.staffBasicInfoVo[item.lable]
                    }
                    // 新增
                    if (v == '财务信息&其它') {
                      item.content = getHcInfoData.staffBasicInfoVo[item.lable]
                    }
                    if (v == '户籍信息') {
                      item.content = getHcInfoData.staffBasicInfoVo[item.lable]
                    }
                    if (v == '紧急联系人') {
                      item.content = getHcInfoData.staffBasicInfoVo[item.lable]
                    }
                    if (v == '联系信息') {
                      item.content = getHcInfoData.staffBasicInfoVo[item.lable]
                    }
                    // 新增end

                    if (v == '未成年子女信息:') {
                      item.content = getHcInfoData.childrenList[item.lable]
                    }
                  } else {
                    item.content = getHcInfoData[index].nameKey[item.lable]
                  }
                })
              } else {
                d.tableKey = getHcInfoData[d.nameTitle]
              }
            }
          })
        })
        this.baseData = deailDataList
        console.log('baseData', deailDataList, this.baseData)
      } else {
        for (let i in getHcInfoData) {
          deailDataList.map((d) => {
            if (d.lable == i) {
              d.content = getHcInfoData[i]
            }
          })
        }
        let obj = {}
        obj.nameTitle = ''
        obj.nameKey = deailDataList
        this.baseData.push(obj)
      }
    },
    goback() {
      this.$router.replace({
        path: '/employees/hired'
      })
    },
    changeButton() {

      this.getHcInfoData = [] //清空上一个详情得数据
      this.agreeTitleArr = []
      this.agreementDataList = []
      this.salaryDataList = []
      this.salaryTitleArr = []
      this.positionTitleArr = []
      this.positionDataList = []
      this.titleArr = [] //清空上一个标题数据
      this.baseData = []
      if(this.jumpParams.userName !== md5(getItem("userName"))){
        this.$message.error("您没有权限查看该员工信息！")
        return
      }
      if(this.$refs.employeeShowManageRef){
        this.$refs.employeeShowManageRef.editSalaryFlag = true
      }
      switch (this.buttonActive) {
        case '1':
          this.getBasicMsg(this.candidateInformationId, this.buttonActive)
          break
        case '2':
          this.getPositionMsg(this.candidateInformationId, this.buttonActive)
          break
        case '3':
          this.getContactMsg(this.candidateInformationId, this.buttonActive)
          break
        case '4':
          this.getSalaryMsg(this.candidateInformationId, this.buttonActive)
          break
        case '7':
          this.getLeaveMsg(this.candidateInformationId, this.buttonActive)
          break
        default:
          break
      }
    }
  },
  beforeDestroy() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.w100 {
  width: 100%;
}
.pr24 {
  padding-right: 24px;
  box-sizing: border-box;
}
.editContainer {
  width: 100%;
  padding: 0px 10px 0 10px;
  box-sizing: border-box;
}
.buttonBottom {
  justify-content: flex-end;
  margin-right: 150px;
  margin-top: 20px;
  .el-button--mini {
    margin-right: 50px;
  }
}
.inductionTitleLab {
  width: 100%;
  border-bottom: 1px solid #828282;
  margin-bottom: 10px;
}
.inductionTitleMsg {
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0 4px 20px;
  box-sizing: border-box;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.upContainer {
  width: 90px;
  margin-top: 7px;
  justify-content: flex-end;
}
.bottomButton {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
}
.bottomBox {
  width: 180px;
  padding: 20px;
  box-sizing: border-box;
  z-index: 99;
  position: fixed;
  bottom: 0;
  right: 20px;
}
.scopeItem {
  width: 104px;
  // margin-right: 20px;
  color: #828282;
  font-size: 14px;
  font-weight: 400;
  // border: 1px solid red;
  text-align: center;
  margin-top: 16px;
}
.active {
  color: #303133;
  text-align: center;
}
.unactive {
  color: #e9eaeb;
  text-align: center;
}
.scopeContainer {
  margin-top: 23px;
}
.guidaoactive {
  padding-top: 8px;
  box-sizing: border-box;
  width: 104px;
  overflow: hidden;
}
.guidaoactivenone {
  padding-top: 8px;
  box-sizing: border-box;
  width: 104px;
  overflow: hidden;
}
.guidao {
  font-size: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .guidaoImg {
    overflow: hidden;
  }
}
.flex {
  display: flex;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.el-range-editor {
  width: 100%;
}
.buttonActive {
  width: 132px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
.buttonunActive {
  width: 132px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
}
.lableDetail {
  color: #4e4e50;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
}
.lableContent {
  color: #545454;
  font-size: 14px;
  // font-weight: 300;
  margin-left: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
}
.lableContent span {
  color: #545454;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
}
</style>
<style lang="scss">
.editInduction-list-page {
  background-color: #F4F4F4;
  height: 100%;
  overflow:auto;
  margin: auto;
  .el-icon-minus:before {
    content: '';
    transform: rotate(180deg);
  }
  .el-icon-plus:before {
    content: '';
  }

  .el-card__body {
    padding: 0 10px 20px 10px !important;
    box-sizing: border-box;
  }

  .irisePocCardTable {
    width: 80%;
    //height: 100%;
    margin: auto;
    .el-card__header {
      height: 65px;
    }
  }
  .el-tabs__header {
    margin: 0 !important;
  }
}
.personTitle {
  font-size: 21px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #868686;
}
</style>
