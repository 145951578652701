let requiredArr = [
  'degreeType',
  'studyStatus',
  'school',
  'major',
  'startDate',
  'endDate'
]   
export const rules = {}
requiredArr.forEach((item) => {
  rules[item] = [{ required: true ,trigger: 'blur'}]
  
})


