<template>
  <!-- 查看详情区域 -->
  <div class="">
    <div class="scrollContainer">
      <div class="editButtonBox" v-if="buttonActive == 4">
        <el-button
          class="editButton"
          type="primary"
          v-if="editSalaryFlag"
          @click="editClick()"
          >编辑</el-button
        >
        <el-button
          class="editButton"
          v-if="!editSalaryFlag"
          @click="cancelEdit"
        >取消</el-button
        >
        <el-button
          class="editButton"
          type="primary"
          v-if="!editSalaryFlag && buttonActive == 4"
          @click="editSalary()"
          >保存</el-button
        >
      </div>
      <div class="editButtonBox" v-if="buttonActive == 2 ">
        <el-button
          role="editPosition"
          v-permission
          class="editButton"
          type="primary"
          v-if="editSalaryFlag"
          @click="editClick()"
          >编辑</el-button
        >
        <el-button
          class="editButton"
          v-if="!editSalaryFlag"
          @click="cancelEdit"
        >取消</el-button
        >
        <el-button
          class="editButton"
          type="primary"
          v-if="!editSalaryFlag && buttonActive == 2"
          @click="inChange()"
        >保存</el-button
        >
      </div>
      <div v-if="editSalaryFlag">
        <div
          class="joinDataTab emploItem"
          v-for="item in baseData"
          :key="item.nameTitle"
        >
          <div
            class="inductionTitleLab inductionTitleMsg"
            v-if="item.nameTitle && item.nameTitle.length "
          >
            {{ item.nameTitle }}
            <!-- 更新子女信息 start -->
            <!-- <span v-show="item.showButtonActive == '1b'"> -->
            <span v-show="item.showButtonActive == '1b'" role="modifyChildrenInfo" v-permission>
              <el-button type="primary" style="margin-left: 20px !important" @click="updateChildrenInformation">
                更新子女信息
              </el-button>
              <el-tooltip class="item" effect="dark" content="点击“更新子女信息”后，系统会向员工邮箱发送邮件邀请员工更新子女信息" placement="right">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
            <!-- 更新子女信息 end -->
          </div>
          <el-form class="filter-form rpm-query-from" v-if="!item.tableKey">
            <div class="flex-1">
              <div class="AlCenter flex-1 rowItem flex flexBetw fleWrap">
                <div
                  class="flexRow"
                  :class="{
                    rowHeight:
                      (index > 2 && buttonActive != 7) ||
                      (index > 0 && buttonActive == 7),
                    flexBetw: buttonActive == 7,
                    'flex-2': items.flexId == '2',
                    'flex-1': items.flexId != '2'
                  }"
                  v-for="(items, index) in item.nameKey"
                  :key="index"
                >
                  <div class="lableDetail4">{{ items.lableTitle }}</div>
                  <el-popover
                    class="item"
                    title=""
                    trigger="hover"
                    width="300"
                    :content="getContentName(items) + ''"
                    placement="top-start"
                  >
                    <div slot="reference" class="lableContent">
                      <span>{{ getContentName(items) }}</span>
                    </div>
                    <div v-if="items.content && items.content.length">
                      {{ getContentName(items) }}
                    </div>
                  </el-popover>
                </div>
              </div>
            </div>
          </el-form>
          <div v-else>
            <employee-table-manage
              :buttonActive="item.showButtonActive"
              :detailshowTableData="item.tableKey"
            />
          </div>
        </div>
      </div>

      <!-- ============================== -->
      <div v-if="!editSalaryFlag">
        <div v-for="(item, indexs) in baseData" :key="item.nameTitle">
          <!--          <div class="inductionTitleLab inductionTitleMsg">-->
          <!--            {{ item.nameTitle }}-->
          <!--          </div>-->
          <div
            class="inductionTitleLab inductionTitleMsg"
            v-if="item.nameTitle && item.nameTitle.length "
          >
            {{ item.nameTitle }}
          </div>
          <el-form class="filter-form rpm-query-from">
            <div class="flex-1">
              <div class="AlCenter flex-1 rowItem flex flexBetw fleWrap">
                <div
                  v-if="buttonActive == 4"
                  class="flexRow"
                  style="width: 30%"
                  v-for="(items, index) in item.nameKey"
                  :key="index"
                >
                  <el-form-item
                    :label="items.lableTitle"
                    style="margin-bottom: 0"
                  >
                    <el-input
                      :disabled="
                        items.lable != 'annualEducationAllowance' &&
                        items.lable != 'annualOtherAllowance' &&
                        items.lable != 'annualMonthlyHousingAllowance' &&
                        items.lable != 'annualLivingAllowance' &&
                        items.lable != 'endowment' &&
                        items.lable != 'medical' &&
                        items.lable != 'unemployment' &&
                        items.lable != 'employmentInjury' &&
                        items.lable != 'maternity' &&
                        items.lable != 'providentFund' &&
                        items.lable != 'annualEducationAllowanceCny'
                      "
                      :type="
                        items.lable == 'endowment' ||
                        items.lable == 'medical' ||
                        items.lable == 'unemployment' ||
                        items.lable == 'employmentInjury' ||
                        items.lable == 'maternity' ||
                        items.lable == 'providentFund'
                          ? 'number'
                          : 'text'
                      "
                      @change="changeInputValue(items, indexs)"
                      v-model="items.content"
                    ></el-input>
                  </el-form-item>
                </div>
                <div
                  v-if="buttonActive == 2"
                  class="flexRow"
                  style="width: 30%"
                  v-for="(items, index) in item.nameKey"
                  :key="index"
                >
                  <el-form-item
                    v-if="items.lable"
                    :label="items.lableTitle"
                    style="margin-bottom: 0"
                  >
                    <el-input
                      v-if="items.lable === 'lineManagerLlid'"
                      v-model="items.content"
                      suffix-icon="el-icon-search"
                      @click.native="selectPerson('induction')"
                      placeholder="请选择"
                      readonly
                    />
                    <el-input
                      v-else-if="items.lable === 'lionIdOfPerformanceManager'"
                      v-model="items.content"
                      suffix-icon="el-icon-search"
                      @click.native="selectPerformanceManager('performance')"
                      placeholder="请选择"
                      readonly
                    />
                    <el-input
                      v-else-if="items.lable === 'ceoName'"
                      v-model="items.content"
                      @click.native="selectCEO('CEO')"
                      placeholder="请选择"
                      readonly
                    >
                      <template #suffix v-if="items.content">
                        <i class="el-icon-close" @click.stop="ceoClose"></i>
                      </template>
                    </el-input>
                    
                    <el-input
                      v-else-if="items.lable === 'ceoName1'"
                      v-model="items.content"
                      suffix-icon="el-icon-search"
                      @click.native="selectCEO1('induction')"
                      placeholder="请选择"
                      readonly
                    >
                      <template #suffix v-if="items.content">
                        <i class="el-icon-close" @click.stop="ceo1Close"></i>
                      </template>
                    </el-input>
                    <el-select
                      v-else-if="['department'].includes(items.lable)"
                      v-model="items.content"
                      clearable
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in items.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.label"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-else-if="items.lable === 'client'"
                      v-model="items.content"
                      clearable
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in items.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.label"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-else-if="['laborCode','descriptionLevel3','parentFunction','childFunction', 'recruitmentChannelId'].includes(items.lable)"
                      v-model="items.content"
                      clearable
                      filterable
                      placeholder="请选择"
                      @change="(val)=>getChild(val,items.lable)"
                    >
                      <el-option
                        v-for="item in items.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-input
                      v-else
                      :disabled="['assigmentProjectLevel2','workSchedules','jobGrade','lineManagerName','buName', 'lineManagerEmail', 'startDate', 'nameOfPerformanceManager'].includes(items.lable)"
                      @change="changeInputValue(items)"
                      v-model="items.content"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </div>

      <!-- ================================== -->
    </div>
    <personnelSelectionDialog
      ref="personnelSelectionDialogRef"
      @onConfirm="onConfirm"
    />
  </div>
</template>

<script>
import employeeTableManage from './employeeTableManage.vue'
import personnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'
import { regionData } from 'element-china-area-data'
import { getChildFunction, getParentFunction } from "@/api/quto";
import { getCodeValue, getDepartmentList, getDescriptionLevel3, getLevel2, getClient1 } from "@/api/personnelRequisition";
import { getOrgSelection } from "@/api/org";

import { NumberAdd } from "@/utils/util";
export default {
  name: 'showManage',
  mixins: [$PCommon.TableMixin],
  components: {
    employeeTableManage,
    personnelSelectionDialog
  },

  data() {
    return {
      clientList: [],
      editSalaryFlag: true,
      regionData: regionData,
      laborCodeList : [],
      departmentList : [],
      descriptionLevel3List : [],
      parentFunctionList : [],
      childFunctionList : [],
      recruitmentChannelList: [],
      assigmentProjectLevel2Id:'',
      descriptionLevel3Id:'',
      descriptionLevel3:'',
      candidateInformationId:'',
      positionId:'',
      oldLm:'',
      onConfirmLable:'',
      selectPersons: {},
      ceo: '',
      ceo1: '',
      ceoType1: '',
    }
  },
  watch: {},

  props: {
    baseData: {
      type: Array
    },
    candidateId: {
      type: Number
    },
    buttonActive: {
      type: String
    },
    ceoId: {
      type: String,
      default:''
    }
  },
  computed: {},

  created() {
    console.log('-----', this.baseData)
    this.ceo = this.ceoId
    console.log('this.ceoId',this.ceoId);
    if(this.buttonActive == '2'){
      this.getFromOption()
    }
  },
  mounted() {

  },

  methods: {
    getContentName(val) {
      if(val.lableTitle == "Recruitment Channel") {
        let res = this.recruitmentChannelList.length > 0 && val.content && val.content != '' ? this.recruitmentChannelList.filter(i => { return i.value == val.content})[0] : {label: ''};
        return res.label;
      } else {
        return val.content;
      }
    },
    //薪水信息才会有编辑
    editClick() {
      this.ceo = this.ceoId
      this.editSalaryFlag = false
    },
    cancelEdit(){
      this.editSalaryFlag = true
      if(this.buttonActive == '2'){
        this.$emit('getPosition')
      }else if(this.buttonActive == '4'){
        this.$emit('getSalarys')
      }
    },
    selectPerson(val) {
      this.dialogStatus = val
      this.onConfirmLable = 'lineManagerLlid'
      this.$refs.personnelSelectionDialogRef.show({
        position: val,
        radio: true
      })
    },
    selectCEO1(val) {
      this.dialogStatus = val
      this.onConfirmLable = 'ceo1'
      this.$refs.personnelSelectionDialogRef.show({
        position: val,
        radio: true
      })
    },
    selectPerformanceManager(val) {
      this.dialogStatus = val
      this.onConfirmLable = 'lionIdOfPerformanceManager'
      this.$refs.personnelSelectionDialogRef.show({
        position: val
      })
    },
    selectCEO(val) {
      this.dialogStatus = val
      this.onConfirmLable = 'ceo'
      this.$refs.personnelSelectionDialogRef.show({
        type: val,
        position: 'Personnel',
        radio:true
      })
    },
    onConfirm(val) {
      if (val && val.length) {
        if(this.onConfirmLable === 'ceo'){
          console.log(val);
          this.baseData[0].nameKey.map(a=>{
            if(a.lable === 'ceoName'){
              a.content = val[0].userName
              this.ceo = val[0].id
            }
          })
          return
        }
        if(this.onConfirmLable === 'ceo1'){
          console.log(val, this.baseData);
          this.baseData[0].nameKey.map(a=>{
            if(a.lable === 'ceoName1'){
              a.content = val[0].userName || val[0].englishName
              this.ceo1 = val[0].lineManagerLiid
              this.ceoType1 = val[0].ceoType1
            }
          })
          return
        }
        val[0].lineManagerBaseId !== undefined ? (this.selectPersons.lineManagerBaseId = val[0].lineManagerBaseId) : ''
        val[0].lineManagerPerformanceBaseId !== undefined ? (this.selectPersons.lineManagerPerformanceBaseId = val[0].lineManagerPerformanceBaseId) : ''
        if(this.onConfirmLable === 'lineManagerLlid'){
          this.baseData[0].nameKey.map(a=>{
            if(a.lable === 'lineManagerLlid'){
              a.content = val[0].lineManagerLiid
            }else if(a.lable === 'lineManagerName'){
              a.content = val[0].englishName || val[0].userName
            } else if(a.lable === 'lineManagerEmail'){
              a.content = val[0].email
            }
          })
        }else{
          this.baseData[0].nameKey.map(a=>{
            if(a.lable === 'lionIdOfPerformanceManager'){
              a.content = val[0].lineManagerLiid
            }else if(a.lable === 'nameOfPerformanceManager'){
              a.content = val[0].englishName || val[0].userName
            }
          })
        }
      }
    },
    ceoClose(){
      this.baseData[0].nameKey.map(a=>{
        if(a.lable === 'ceoName'){
          a.content = ''
          this.ceo = ''
        }
      })
    },
    ceo1Close(){
      this.baseData[0].nameKey.map(a=>{
        if(a.lable === 'ceoName1'){
          a.content = ''
          this.ceo1 = ''
          this.ceoType1 = ''
          
        }
      })
    },
    async getFromOption(assigmentProjectLevel2Id,descriptionLevel3Id,candidateInformationId,positionId){
      if(descriptionLevel3Id){
        this.descriptionLevel3Id = descriptionLevel3Id
      }
      if(candidateInformationId){
        this.candidateInformationId = candidateInformationId
      }
      if(positionId){
        this.positionId = positionId
      }
      await this.getRecruitmentChannelList()
      let codeValue = await getCodeValue()
      if(codeValue && codeValue.code && codeValue.code === 200){
        this.laborCodeList = codeValue.data.labor_code
      }
      let clientList = await getClient1()
      if(clientList && clientList.code && clientList.code === 200){
        this.clientList = clientList.data
      }
      if(assigmentProjectLevel2Id){
        this.assigmentProjectLevel2Id = assigmentProjectLevel2Id
      }
      let parentData = await getParentFunction()
      if (parentData && parentData.code && parentData.code === 200) {
        if (parentData.data) {
          this.parentFunctionList = parentData.data
        }
      }
      let childFun = await getChildFunction()
      if (childFun && childFun.code && childFun.code === 200) {
        if (childFun.data) {
          this.childFunctionList = childFun.data
        }
      }
      const data = {
        assigmentProjectLevel2: this.assigmentProjectLevel2Id
      }
      let descriptionLevel3 = await getDescriptionLevel3(data)
      if (descriptionLevel3.code && descriptionLevel3.code === 200) {
        if (descriptionLevel3.data) {
          this.descriptionLevel3List = descriptionLevel3.data
        }
      }
     let departmentList = await getDepartmentList()
      if (departmentList.code && departmentList.code === 200) {
        if (departmentList.data) {
          this.departmentList = departmentList.data
        }
      }
      if(this.buttonActive == '2'){
        this.baseData[0].nameKey = this.baseData[0].nameKey.map(a=>{
          if(a.lable === 'laborCode'){
            a.options = this.laborCodeList
          }else if(a.lable === 'department'){
            a.options = this.departmentList
          }else if(a.lable === 'descriptionLevel3'){
            a.options = this.descriptionLevel3List
          }else if(a.lable === 'parentFunction'){
            a.options = this.parentFunctionList
          }else if(a.lable === 'childFunction'){
            a.options = this.childFunctionList
          }else if(a.lable === 'recruitmentChannelId'){
            console.log('recruitmentChannelId',a)
            a.options = this.recruitmentChannelList;
          } else if (a.lable === 'client'){
            a.options = this.clientList;
          }
          if(a.lable === 'lineManagerName'){
            this.oldLm = a.content
          }
          if(a.lable === 'ceo'){
            this.ceo = a.content
          }
          if(a.lable === 'ceo1'){
            this.ceo1 = a.ceo1
          }

          return a
        })
      }
    },
    async getChild(val,label) {
      if(val){
        if(label ==='parentFunction'){
          const data = {
            parentFunction: val
          }
          let childFun = await getChildFunction(data)
          if (childFun && childFun.code && childFun.code === 200) {
            if (childFun.data) {
              this.childFunctionList = childFun.data
              this.baseData[0].nameKey.map(a=>{
                if(a.lable === 'childFunction'){
                  a.content = ''
                  a.options = this.childFunctionList
                }
              })
            }
          }
        }else if(label ==='descriptionLevel3'){
          this.descriptionLevel3 = this.descriptionLevel3List.filter(a=>a.value===val)[0].label
          this.descriptionLevel3Id = val
        }
      }

    },
    // 对原有数据结构进行改变，感觉后端的变了
    editSalary() {
      console.log('-----******', this.baseData)
      // return
      let res = []
      let obj = {}
      this.baseData.map((v) => {
        v.nameKey.map((m) => {
          // if(["annualEducationAllowanceCny", "annualMonthlyHousingAllowance", "annualLivingAllowance", "annualOtherAllowance"].includes(m.lable)) {
          //   console.log('11111111', m)
          //   obj[m.lable] = (Number(m.content) * 12).toFixed(2);
          //   console.log('11222222111111', obj[m.lable])
          // } else {
          //   obj[m.lable] = m.content
          // }
          obj[m.lable] = m.content
          // obj['id'] = m.flexId
        })
        // res.push(obj)
      })
      console.log('最终数据---', obj)
      this.submit(obj)
    },
    inChange(){
      const h = this.$createElement;
       this.$Api.staff.inChange(this.candidateInformationId)
         .then(async res => {
           if (res.code === 200 && res.data) {
             if (res.data.inApprovalStatus) {
               let msg = h('p', null, [
                 h('p', null, `员工正处于 ${res.data.busTypeDesc} 流程中，原LM ${res.data.lineManagerName} 未审批`),
                 h('p', null, '是否重新发送LM审批邮件？')
               ])
               if (res.data.lmApprovalStatus) {
                 msg = h('p', null, [
                   h('p', null, `员工正处于 ${res.data.busTypeDesc} 流程中，原LM ${res.data.lineManagerName} 已审批`),
                   h('p', null, `若重新发送LM审批，流程将会重置`),
                   h('p', null, '是否重新发送LM审批邮件？')
                 ])
               }
               this.$msgbox({
                 showCancelButton: true,
                 distinguishCancelAndClose: true,
                 confirmButtonText: '是',
                 cancelButtonText: '否',
                 title: '是否重新发送LM审批邮件',
                 message: msg,
                 beforeClose: async (action, instance, done) => {
                   console.log(action);
                   if (action === 'confirm') {
                     instance.confirmButtonLoading = true;
                     instance.cancelButtonLoading = true;
                     await this.editPosition(true)
                     instance.confirmButtonLoading = false;
                     instance.cancelButtonLoading = false;
                     done();
                   } else if (action === 'cancel') {
                     instance.confirmButtonLoading = true;
                     instance.cancelButtonLoading = true;
                     await this.editPosition(false)
                     instance.confirmButtonLoading = false;
                     instance.cancelButtonLoading = false;
                     done();
                   } else {
                     done();
                   }
                 }
               })
             }
           } else if (res.code === 200 && !res.data) {
             await this.editPosition(true)
           }
         })
    },
   async editPosition(approvalYes){
      console.log('-----******', this.baseData)
      // return
      let res = []
      let obj = {}
      this.baseData.map((v) => {
        v.nameKey.map((m) => {
          if (m.lable == "recruitmentChannelId") {
            obj["recruitmentChannelValue"] = m.content && m.content != '' ? m.options.filter(i => i.value == m.content)[0].label : '';
          }
          obj[m.lable] = m.content
          // obj['id'] = m.flexId
        })
        // res.push(obj)
      })
      if(this.descriptionLevel3){
        obj.descriptionLevel3Id = obj.descriptionLevel3
        obj.descriptionLevel3 = this.descriptionLevel3
      }
      if(this.descriptionLevel3Id){
        obj.descriptionLevel3Id = this.descriptionLevel3Id
      }
      if(this.positionId){
        obj.id = this.positionId
      }
      this.selectPersons.lineManagerBaseId !== undefined ? (obj.lineManagerBaseId = this.selectPersons.lineManagerBaseId) : ''
      this.selectPersons.lineManagerPerformanceBaseId !== undefined ? (obj.lineManagerPerformanceBaseId = this.selectPersons.lineManagerPerformanceBaseId) : ''
      obj.approvalYes = approvalYes
      obj.ceo = this.ceo
      obj.ceo1 = this.ceo1
      obj.ceoType1 = this.ceoType1      
      console.log('提交项',obj)
      await this.editLm(obj)
    },
    async editLm(obj){
      const { data, code, msg } = await this.$Api.staff.editLm(obj)
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '保存成功'
        })
        this.editSalaryFlag = true
        console.log('aaa------')
        // 更改完调重新获取刷新接口
        this.$emit('getPosition')
      }
    },
    changeInputValue(item, index = -1) {
      console.log('changeInputValue', item, index)
      if (item && !item.content.length) {
        item.content = null
      } else {
        if (["annualEducationAllowanceCny", "annualMonthlyHousingAllowance", "annualLivingAllowance", "annualOtherAllowance"].includes(item.lable) && index != -1) {
          let total = 0;
          this.baseData[index].nameKey.forEach(i => {
            if(["annualEducationAllowanceCny", "annualMonthlyHousingAllowance", "annualLivingAllowance", "annualOtherAllowance"].includes(i.lable)) {
              console.log('进入',i)
              total = (Number(total) + Number(i.content)).toFixed(2);
              // total = NumberAdd(Number(total), Number(i.content));

            }
          })
          this.baseData[index].nameKey.forEach(i => {
            if(i.lable == 'totalAnnualAllowance') {
              i.content = total;
            }
          })
        }
      }
    },
    async submit(editSalaryData) {
      const { data, code, msg } = await this.$Api.staff.editSalary({
        list: [
          {
            ...editSalaryData
          }
        ]
      })
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '编辑成功'
        })
        this.editSalaryFlag = true
        console.log('aaa------')
        // 更改完调重新获取刷新接口
        this.$emit('getSalarys')
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    async getRecruitmentChannelList() {
      const { data, code, msg } =
      await this.$Api.induction.getOfferProposalSelectedData({});
      if (code === 200) {
        data.map((d) => {
          if (d.description == 'recruitment_channel') {
            this.recruitmentChannelList = d.list;
          }
        })
      }
    },
    /**
     * 更新子女信息
     */
     updateChildrenInformation(item) {
      // 判断员工 business email是否存在
      let businessMailFlag = false
      this.baseData.forEach(element => {
        if (!element.nameKey) {
          return
        }
        element.nameKey.forEach(element1 => {
          if (element1.lable == 'businessMail') {
            if(element1.content) {
              businessMailFlag = true
            }
          }
        });
      });
      // 若员工无 business email，则提示“请先补充员工Business  Email“
      if (!businessMailFlag) {
        this.$message({
          message: '请先补充员工Business  Email',
          type: 'warning'
        });
        return
      }
      // business email 存在的情况下  调用接口 进行邮件发送
      this.$confirm('是否向员工邮箱发送子女信息更新邮件？', '发送子女信息更新邮件', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 调用接口 获取子女信息
          this.$Api.induction.getChildrenSendModifyRequestEmail({candidateId: this.candidateId}).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: '邮件发送成功',
                type: 'success',
              });
            }
          })
        }).catch(() => {
        });
     }
  },
  beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.w100 {
  width: 100%;
}
.pr24 {
  padding-right: 24px;
  box-sizing: border-box;
}
.rowItem {
  // margin-top: 10px;
}
.rowHeight {
  margin-top: 12px;
  // border: 1px solid red;
}
.scrollContainer {
  //height: calc(100vh - 250px) !important ;
  overflow: auto;
  //padding: 20px;
  box-sizing: border-box;
  .editButtonBox {
    width: 100%;
    height: 30px;
    // border: 1px solid red;
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
  .editButton {
    margin-right: 90px;
  }
}
.inductionTitleLab {
  width: 100%;
  // border-bottom: 1px solid #828282;
  margin-bottom: 10px;
}
.inductionTitleMsg {
  font-size: 16px;
  font-weight: bold;
  color: #303133;
  box-sizing: border-box;
}
.flexRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  //justify-content: space-between;
  :deep(.item) {
    position: relative;
  }
}
.upContainer {
  width: 90px;
  margin-top: 7px;
  justify-content: flex-end;
}
.flex {
  display: flex;
}
.fleWrap {
  display: flex;
  flex-wrap: wrap;
  // border: 1px solid red;
}
.el-range-editor {
  width: 100%;
}
.buttonActive {
  width: 132px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}

.lableDetail {
  color: #868686;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  // border:1px solid red;
  width: 190px;
}
.lableDetail4 {
  color: #868686;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  // border:1px solid red;
  width: 140px;
}
.lableContent {
  width: 180px;
  color: #323435;
  font-size: 14px;
  margin-left: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lableContentnone {
  width: 100%;
}
.lableContent span {
  color: #323435;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
}
</style>
<style lang="scss">
.editInduction-list-page {
  .el-icon-minus:before {
    content: '';
    transform: rotate(180deg);
  }
  .el-icon-plus:before {
    content: '';
  }

  .el-card__body {
    padding: 0 10px 20px 10px !important;
    box-sizing: border-box;
  }

  .irisePocCardTable {
    //height: 100%;
    .el-card__header {
      height: 65px;
    }
  }
}
.emploItem {
  margin-top: 20px;
  max-width: 1226px;
}
</style>
