<template>
  <div class="employeeUpload">
    <el-form :inline="true" label-position="top">
      <div class="upload-item">
        <el-form-item label="最高学历证明">
          <pgUploadFile
            :id="offerProposalId"
            url="candidateUpload"
            :limit="disabledFlag"
            type="EducationGraduationCertificate"
            :upload-list-call="uploadFile.educationGraduationCertificate"
          />
        </el-form-item>

        <el-form-item label="学位证书">
          <pgUploadFile
            :id="offerProposalId"
            url="candidateUpload"
            :limit="disabledFlag"
            type="DegreeCertificate"
            :upload-list-call="uploadFile.degreeCertificate"
          />
        </el-form-item>
        <el-form-item label="简历">
          <pgUploadFile
            :id="offerProposalId"
            url="candidateUpload"
            :limit="disabledFlag"
            type="Resume"
            :upload-list-call="uploadFile.resume"
          />
        </el-form-item>
        <el-form-item label="回签Offer">
          <pgUploadFile
            :id="offerProposalId"
            url="candidateUpload"
            :limit="disabledFlag"
            type="SignedOfferLetter"
            :upload-list-call="uploadFile.signedOfferLetter"
          />
        </el-form-item>
      </div>
      <div class="upload-item">
        <el-form-item label="身份证复印件">
          <pgUploadFile
            :id="offerProposalId"
            url="candidateUpload"
            :limit="disabledFlag"
            type="IdCard"
            :upload-list-call="uploadFile.idCard"
          />
        </el-form-item>
        <el-form-item label="户口本复印件">
          <pgUploadFile
            :id="offerProposalId"
            url="candidateUpload"
            :limit="disabledFlag"
            type="ResidenceDocument"
            :upload-list-call="uploadFile.residenceDocument"
          />
        </el-form-item>
        <el-form-item label="子女证件复印件">
          <pgUploadFile
            :id="offerProposalId"
            url="candidateUpload"
            :limit="disabledFlag"
            type="ChildrenIdCard"
            :upload-list-call="uploadFile.childrenIdCard"
          />
        </el-form-item>
        <el-form-item label="银行卡">
          <pgUploadFile
            :id="offerProposalId"
            url="candidateUpload"
            :limit="disabledFlag"
            type="Bank"
            :upload-list-call="uploadFile.bank"
          />
        </el-form-item>
      </div>
      <div class="upload-item">
        <el-form-item label="体检报告">
          <pgUploadFile
            :id="offerProposalId"
            url="candidateUpload"
            :limit="disabledFlag"
            type="MedicalReport"
            :upload-list-call="uploadFile.medicalReport"
          />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import pgUploadFile from '@/components/pg-uploadFile/pg-uploadFile'
import PGTitle from '@/components/PG-title/PG-title'
export default {
  name: 'uploadManage',
  components: {
    pgUploadFile,
    PGTitle
  },
  data() {
    return {
      offerProposalId: '',
      disabledFlag: 0,
      uploadFile: {
        //候选人中国银行银行卡复印件
        bank: [],
        //子女出生证或户口本复印件
        childrenIdCard: [],
        //候选人最高学位证书
        degreeCertificate: [],
        //候选人最高学历证明
        educationGraduationCertificate: [],
        //候选人身份证复印件
        idCard: [],
        //候选人体检报告
        medicalReport: [],
        //候选人户口本复印件
        residenceDocument: [],
        //候选人简历
        resume: [],
        //候选人已经签署的录取通知书
        signedOfferLetter: []
      }
    }
  },
  props: {
    jumpParams: {
      type: Object
    },
    fromFlag: {
      type: String
    }
  },
  created() {
    this.disabledFlag = this.jumpParams.jumpFlag === 'goDetail' ? 0 : 1
    this.offerProposalId = this.jumpParams.candidateInformationId
    this.getUploadList(this.offerProposalId)
  },
  methods: {
    save() {},
    async getUploadList(id) {
      const data = await this.$Api.candidate.getuploadList({
        id
      })
      if (data && data.code === 200) {
        Object.keys(this.uploadFile).forEach((item) => {
          this.uploadFile[item] = data.data[item]
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.employeeUpload {
  height: 100%;
  overflow-x: auto;
  .upload-item {
    display: flex;
    //margin-bottom: -30px !important;

    :deep(.el-form-item) {
      margin-right: 24px !important;

      .el-form-item__label {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303133;
        margin-bottom: 7px;
      }
    }
  }
}
</style>
