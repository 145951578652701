let agreementData = {
  nameTitle: '',
  nameKey: [
    {
      flexId: '1',
      lable: 'workingLocation',
      lableTitle: 'Working Location',
      content: ''
    },
    {
      flexId: '1',
      lable: 'officeLocation',
      lableTitle: 'Office Location',
      content: ''
    },
    {
      flexId: '1',
      lable: 'contractType',
      lableTitle: 'Contract Type',
      content: ''
    },
    {
      flexId: '1',
      lable: 'legalEntity',
      lableTitle: 'Legal entity',
      content: ''
    },
    {
      flexId: '1',
      lable: 'socialInsuranceRegistrationCity',
      lableTitle: 'Social Insurance Registration City',
      content: ''
    },
    {
      flexId: '1',
      lable: 'contractPeriodYear',
      lableTitle: 'Contract Period(Years)',
      content: ''
    },
    {
      flexId: '1',
      lable: 'noticePeriodMonth',
      lableTitle: 'Notice Period(Months)',
      content: ''
    },

    {
      flexId: '1',
      lable: 'probationPeriodMonth',
      lableTitle: 'Probation Period（Months)',
      content: ''
    },
    {
      flexId: '1',
      lable: 'probationPeriodEndDate',
      lableTitle: 'Probation Period End Date',
      content: ''
    },
    {
      flexId: '1',
      lable: 'contractDueDate',
      lableTitle: 'Contract Due Date',
      content: ''
    },
    {
      flexId: '1',
      lable: 'noticePeriodDaysWithProbation',
      lableTitle: 'Notice Period Days(within probation)',
      content: ''
    },
    { flexId: '1', lable: 'id', lableTitle: 'id', content: '' }
  ]
}
// lable对应后端返回字段、前端名字对应lableTitle
// content 对应后端返回字段值
// flexId对应flex布局占几块
// typeId 对应是否是表格（1是表格、其他为表单排列）

export const ConcatApiObj = {
  candidateInformationId: null,
  contactAddress: null,
  contractPeriodYear: null,
  contractType: null,
  emergencyContact: null,
  emergencyContactPhone: null,
  endDate: null,
  id: null,
  idNumber: null,
  legalEntity: null,
  legalEntityCn: null,
  noticePeriodDaysWithProbation: null,
  noticePeriodMonth: null,
  officaLocationCn: null,
  officeLocation: null,
  personalEmail: null,
  positionId: null,
  postProbationEndDate: null,
  postProbationStartDate: null,
  postal: null,
  probationPeriodMonth: null,
  registeredAddress: null,
  salaryId: null,
  socialInsuranceRegistrationCity: null,
  startDate: null,
  status: null,
  telephoneNo: null,
  weChat: null,
  workCityCn: null,
  workingLocation: null
}

export const agreementDataObj = agreementData
