let resignData = [
  {
    flexId: '1',
    lable: 'lastWorkingDate',
    lableTitle: 'Last Working Date',
    content: ''
  },
  {
    flexId: '1',
    lable: 'lastEmplomentDate',
    lableTitle: 'Last Employment Date',
    content: ''
  },
  {
    flexId: '1',
    lable: 'paidOutAnnualLeaveDays',
    lableTitle: 'Paid Out Annual Leave Days',
    content: ''
  },
  {
    flexId: '1',
    lable: 'overtakenAnnualLeaveDays',
    lableTitle: 'Overtaken Annual Leave Days',
    content: ''
  },
  {
    flexId: '1',
    lable: 'socialInsuranceHousingFundPaidTill',
    lableTitle: 'Social Insurance/Housing Fund paid till',
    content: ''
  },
  { flexId: '1', lable: 'leaveType', lableTitle: 'Leave Type', content: '' },
  {
    flexId: '1',
    lable: 'leaveReason',
    lableTitle: 'Leave Reason',
    content: ''
  },

  {
    flexId: '1',
    lable: 'destinationProfileVoluntaryLeaversGo',
    lableTitle: 'Destination profile-Where did the Voluntary leavers go',
    content: ''
  },
  {
    flexId: '1',
    lable: 'destinationCompanyComment',
    lableTitle: 'Destination Company Name/Comment',
    content: ''
  }
]
// lable对应后端返回字段、前端名字对应lableTitle
// content 对应后端返回字段值
// flexId对应flex布局占几块
// typeId 对应是否是表格（1是表格、其他为表单排列）

export const resignDataList = resignData
