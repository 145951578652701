<script>
export default {
  props: {
    attachmentList: {
      type: Array
    }
  },
  name: 'attachmentMapListDisplay',
  methods: {
    openUpload(url) {
      window.open(url)
    }
  },
  render(createElement, context) {
    const mapAttachmentList = () => {
      if (this.attachmentList && this.attachmentList.length) {
        return this.attachmentList.map((item) => {
          return (
            <div
              class="attachmentMapListDisplay__item"
              onClick={() => {
                this.openUpload(item.url)
              }}
            >
              {item.fileName}
            </div>
          )
        })
      } else {
        return <div>-</div>
      }
    }
    return <div className="attachmentMapListDisplay">{mapAttachmentList()}</div>
  }
}
</script>

<style lang="scss" scoped>
.attachmentMapListDisplay {
  &__item {
    &:hover {
      cursor: pointer;
      color: $--color-button-primary;
    }
  }
}
</style>
