<template>
  <div>
    <div class="employeeTable-list-page">
      <PocTable
        v-loading="queryLoading"
        :data="tableData"
        stripe
        hidepage
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        :max-height="maxHeight"
        ref="multipleTable"
        row-key="id"
      >
        <el-table-column
          v-if="buttonActive !== '1b'"
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
          align="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          v-if="buttonActive === '1b'"
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
          align="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="item.key == 'gender'">
              {{ scope.row.childrenInfo[item.key] == 1 ? '男' : '女' }}
            </div>
            <div v-else-if="item.key == 'idType'">
              {{ idTypeOptions[scope.row.childrenInfo[item.key]] }}
            </div>
            <div v-else-if="item.key == 'isInsure'">
              {{ scope.row.childrenInfo[item.key] ? '是' : '否' }}
            </div>
            <div v-else-if="item.key == 'childrenFile'">
              <div
                v-for="(fileItem, index) in scope.row.fileInfoList"
                :key="index"
              >
                <el-button
                  @click="openUpload(fileItem.url)"
                  type="text"
                  style="
                    width: 140px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  >{{ fileItem.fileName }}</el-button
                >
              </div>
            </div>
            <div v-else>{{ scope.row.childrenInfo[item.key] }}</div>
          </template>
        </el-table-column>

        <el-table-column
          label="Operation"
          width="150"
          align="center"
          fixed="right"
          v-if="buttonActive != '1a' && buttonActive != '1b'"
        >
          <template slot-scope="scope">
            <el-row :gutter="12" style="display: flex; justify-content: center">
              <el-col :span="8">
                <el-tooltip content="新增" placement="top">
                  <img
                    class="iconImg pointer"
                    src="@/assets/img/add.png"
                    @click.stop="addRow(scope.row)"
                    alt=""
                  />
                </el-tooltip>
              </el-col>
              <el-tooltip content="编辑" placement="top">
                <img
                  class="iconImg pointer"
                  src="@/assets/img/edit.png"
                  @click.stop="editdRow(scope.row)"
                  alt=""
                />
              </el-tooltip>
              <el-col :span="8">
                <el-tooltip content="删除" placement="top">
                  <img
                    class="iconImg"
                    src="@/assets/img/delete.png"
                    @click.stop="deleteRow(scope.row)"
                    alt=""
                  />
                </el-tooltip>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </PocTable>
    </div>
    <education-dialog
      ref="educationDialogRef"
      :dialogVisibleEducation.sync="dialogVisibleEducation"
      :rowItem="rowItem"
      @close="close"
      @confirm="confirm"
    />
    <work-dialog
      ref="workDialogRef"
      :dialogVisibleWork.sync="dialogVisibleWork"
      :rowItem="rowItem"
      @close="close"
      @confirm="confirm"
    />
  </div>
</template>

<script>
const { Vuex } = $PCommon
import workDialog from './dialog/workDialog.vue'
import educationDialog from './dialog/educationDialog.vue'
export default {
  name: 'employeeJoin',
  mixins: [$PCommon.TableMixin],
  components: {
    workDialog,
    educationDialog
  },
  props: {
    buttonActive: {
      type: String
    },
    detailshowTableData: {
      type: Array
    },
    offerProposalId: {
      type: Number
    },
    candidateInformationId: {
      type: Number
    },
    idNumber: {
      type: String
    }
  },
  watch: {
    buttonActive(newValue, oldValue) {
      if (newValue) {
        if (newValue == '5') {
          this.filterQuery(this.offerProposalId)
        } else {
          this.filterQuery(this.idNumber)
        }
      }
    }
  },

  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight + 'px'
    return {
      maxHeight: h,
      queryLoading: false,
      tableData: [],
      rules: {},
      columnConfig1a: [
        {
          key: 'immediate',
          name: 'Name',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'relationName',
          name: 'Working Department/Position',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: '250' // 默认宽度，像素
        },
        {
          key: 'relationAppellation',
          name: 'Relation',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: '' // 默认宽度，像素
        }
      ],
      columnConfig1b: [
        {
          key: 'name',
          name: 'Name',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'gender',
          name: 'Gender',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'idType',
          name: 'ID Type',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 250 // 默认宽度，像素
        },
        {
          key: 'idNumber',
          name: 'ID NO',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: '' // 默认宽度，像素
        },
        {
          key: 'birthday',
          name: 'Birthday',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'isInsure',
          name: '需要投保',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'childrenFile',
          name: '文件',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        }
      ],
      columnConfig5: [
        {
          key: 'school',
          name: 'School',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'degreeType',
          name: 'Degree Type',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'major',
          name: 'Major Subject',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'startDate',
          name: 'Start Date',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'endDate',
          name: 'End Date',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'degreeReceived',
          name: 'Degree Received',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'bachelorObtained',
          name: 'Bachelor Obtained',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        }
      ],
      columnConfig6: [
        {
          key: 'companyName',
          name: 'Company',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 90
        },
        {
          key: 'periodStart',
          name: 'Period Start',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 110
        },
        {
          key: 'periodEnd',
          name: 'Period End',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 110
        },
        {
          key: 'lastJobTitle',
          name: 'Job Title',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 100
        },
        {
          key: 'lineManagerName',
          name: 'Line Manager',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'lineManagerPhone',
          name: 'Line Manager Phone',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'lineManagerEmail',
          name: 'Line Manager Email',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'hrName',
          name: 'HR',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 130 // 默认宽度，像素
        },
        {
          key: 'hrPhone',
          name: 'HR Phone',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 130 // 默认宽度，像素
        },
        {
          key: 'hrEmail',
          name: 'HR Email',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false // 固定列的位置(left, right)
        }
      ],
      dialogVisibleWork: false,
      dialogVisibleEducation: false,
      addOredit: '',
      rowItem: {},
      newcandidateInformationId: '',
      // 证件类型 选择
      idTypeOptions: {
        'ID Card': '身份证',
        Passport: '护照',
        'HK/MO Pass': '港澳通行证',
        'Taiwan Pass': '台胞证',
        Other: '其他'
      }
    }
  },
  computed: {
    columnOptions() {
      switch (this.buttonActive) {
        case '1a':
          return this.columnConfig1a.filter((item) => item.visible)
          break
        case '1b':
          return this.columnConfig1b.filter((item) => item.visible)
          break
        case '5':
          return this.columnConfig5.filter((item) => item.visible)
          break
        case '6':
          return this.columnConfig6.filter((item) => item.visible)
          break
        default:
          break
      }
    }
  },

  async created() {
    if (this.buttonActive == '1a' || this.buttonActive == '1b') {
      //自带数据过来
      this.tableData = this.detailshowTableData
      this.pageConfig.total = this.detailshowTableData.length
    } else {
      this.filterQuery(this.offerProposalId)
    }
  },
  methods: {
    /**
     * 图片预览
     */
    openUpload(url) {
      window.open(url)
    },
    confirm() {
      console.log('333333333')
      if (this.buttonActive == '5') {
        this.editEducation()
      } else if (this.buttonActive == '6') {
        this.editWork()
      }
    },
    async editEducation() {
      const nowDate = Date.parse(new Date())
      const { data, code, msg } = await this.$Api.staff.editEducation({
        ...this.$refs.educationDialogRef.ruleForm
      })
      let Msg = ''
      if (this.addOredit == 'add') {
        Msg = '新增成功'
      } else {
        Msg = '编辑成功'
      }

      if (code === 200) {
        this.$message({
          type: 'success',
          message: Msg
        })
        this.close()
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    async editWork() {
      let obj = { ...this.$refs.workDialogRef.ruleForm }
      if (this.addOredit == 'add')
        obj.candidateInformationId = this.newcandidateInformationId
      console.log('-------', obj)
      const { data, code, msg } = await this.$Api.staff.editEmploymentHistory(
        obj
      )
      let Msg = ''
      if (this.addOredit == 'add') {
        Msg = '新增成功'
      } else {
        Msg = '编辑成功'
      }

      if (code === 200) {
        this.$message({
          type: 'success',
          message: Msg
        })
        this.close()
        // 新增成功 刷新列表
        this.getworkMsg()
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    close() {
      this.dialogVisibleWork = false
      this.dialogVisibleEducation = false
    },
    filterQuery(offerProposalId) {
      this.pageConfig.pageNum = 1
      this.queryApi(offerProposalId)
    },
    // 获取基础数据维护的列表
    async queryApi(offerProposalId) {
      if (this.buttonActive == '5') {
        this.getEducationMsg(offerProposalId)
      } else if (this.buttonActive == '6') {
        this.getworkMsg(this.idNumber)
      }
    },
    async getEducationMsg(offerProposalId, dataType) {
      this.queryLoading = true
      const { data, code, msg } = await this.$Api.staff.getEducationMsg({
        ...this.pageConfig,
        id: this.candidateInformationId
      })
      if (code === 200) {
        this.queryLoading = false
        this.tableData = data ? data : []
        this.pageConfig.total = data.total
        this.$forceUpdate()
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    async getworkMsg(idNumber, dataType) {
      this.queryLoading = true
      const { data, code, msg } = await this.$Api.staff.getworkMsg({
        vo: idNumber,
        id: this.candidateInformationId
      })
      if (code === 200) {
        this.queryLoading = false
        this.tableData = data ? data : []
        this.pageConfig.total = data.total
        this.$forceUpdate()
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    addRow(row) {
      if (this.buttonActive == '5') {
        this.dialogVisibleEducation = true
      } else if (this.buttonActive == '6') {
        this.dialogVisibleWork = true
        this.newcandidateInformationId = row.candidateInformationId
        console.log('candidateInformationId', this.newcandidateInformationId)
      }
      this.rowItem = {}
      this.offerProposalId = row.offerProposalId
      // this.rowItem.offerProposalId = row.offerProposalId
      this.addOredit = 'add'
    },
    editdRow(row) {
      if (this.buttonActive == '5') {
        this.dialogVisibleEducation = true
      } else if (this.buttonActive == '6') {
        this.dialogVisibleWork = true
      }
      this.rowItem = row
      console.log('99999999999999', row, this.rowItem)
      this.addOredit = 'edit'
    },
    deleteRow(row) {
      if (this.buttonActive == '5') {
        this.deleteEducation(row.id)
      } else {
        this.deleteEmployment(row.id)
      }
    },
    async deleteEmployment(deleteId) {
      const { data, code, msg } = await this.$Api.staff.deleteEmploymentHistory(
        {
          id: deleteId
        }
      )
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '删除成功'
        })
        this.queryApi()
        this.$forceUpdate()
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    },
    async deleteEducation(deleteId) {
      const { data, code, msg } = await this.$Api.staff.deleteEducation({
        id: deleteId
      })
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '删除成功'
        })
        this.queryApi()
        this.$forceUpdate()
      } else {
        this.$message({
          type: 'error',
          message: msg
        })
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
    window.removeEventListener('keyup', () => {})
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if ((e.code == 'Enter' || e.target == 13) && e.target.id == 'inputid') {
        console.log(e)
        this.filterQuery()
      }
    })
    window.addEventListener('resize', () => {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 340 + 'px'
      this.maxHeight = h
    })
  }
}
</script>
<style lang="scss" scoped>
.employeeTable-list-page {
  margin-top: 20px;
}
.opeationText1 {
  // width: 45px;
  // height: 16px;
  padding: 0 10px 0 10px !important;
  box-sizing: border-box;
  font-size: 8px;
}
.opeationText {
  // width: 90px;
  // height: 16px;
  padding: 0 10px 0 10px !important;
  box-sizing: border-box;
  font-size: 8px;
}
.opeationText:hover,
.opeationText1:hover {
  color: #ba9765;
}
.flex {
  display: flex;
}
.iconImg {
  width: 16px;
  height: 16px;
}
</style>
